import AllFieldsRequiredException from "../../types/Exceptions/AllFieldsRequiredException";
import { AuthInterface } from "@almservices-cl/coach-app-shared-components";
import PasswordsDontMatchException from "../../types/Exceptions/PasswordsDontMatchException";
import { emailRegex } from "../utils/RegexUtils";
import InvalidFormInputDataFormat from "../../types/Exceptions/InvalidFormInputDataFormat";
import * as sentry from "@sentry/react";
import cache from "../../cache-layer/Cache";

export interface LoginServiceInterface {
  signInUser: (login: string, password: string) => Promise<void | Error>;
  signOutUser: () => Promise<void>;
  changePassword: (
    oldPassword: string,
    newPassword: string,
    newPasswordRepeated: string
  ) => Promise<void>;
  resetUserPassword: (email: string) => Promise<void>;
  authByRefreshToken: (token: string) => Promise<void>;
}

export default class LoginService implements LoginServiceInterface {
  constructor(private authClient: AuthInterface) {}

  signInUser = (login: string, password: string): Promise<void | Error> => {
    this.validateFormSignIn(login, password);
    return this.authClient
      .logIn({
        login,
        password,
      })
      .then(() => this.setTrackingInfo(login));
  };

  private setTrackingInfo = (email: string): void => {
    sentry.setUser({ email, env: cache.getValue("env") });
  };

  signOutUser = async (): Promise<void> => {
    await this.authClient.logout();
    localStorage.clear();
  };

  private validateFormSignIn = (login: string, password: string): void => {
    if (!login.trim() || !password.trim()) {
      throw new AllFieldsRequiredException();
    }
  };

  changePassword = async (
    oldPassword: string,
    newPassword: string,
    newPasswordRepeated: string
  ): Promise<void> => {
    this.validateFormChangePassword(
      oldPassword,
      newPassword,
      newPasswordRepeated
    );
    return this.authClient.changePassword(
      oldPassword,
      newPassword,
      newPasswordRepeated
    );
  };

  private validateFormChangePassword = (
    oldPassword: string,
    newPassword: string,
    newPasswordRepeated: string
  ): void => {
    if (
      !oldPassword.trim() ||
      !newPassword.trim() ||
      !newPasswordRepeated.trim()
    ) {
      throw new AllFieldsRequiredException();
    }
    if (newPassword !== newPasswordRepeated) {
      throw new PasswordsDontMatchException();
    }
  };

  resetUserPassword = (email: string): Promise<void> => {
    this.validateFormResetUserPassword(email);
    return this.authClient.requestPasswordChange(email);
  };

  validateFormResetUserPassword = (email: string): void => {
    if (email.trim() === "") {
      throw new AllFieldsRequiredException();
    }
    if (!email.match(emailRegex)) {
      throw new InvalidFormInputDataFormat();
    }
  };

  authByRefreshToken = (token: string): Promise<void> => {
    return this.authClient.auth(token);
  };
}
