import { useContext, useState, createContext, useMemo, FC } from "react";
import { Application } from "../../types/Application";

type ApplicationContextType = {
  application: Application;
  setApplicationContext(application: Application): void;
};

const ApplicationContext = createContext<ApplicationContextType>(
  null as unknown as ApplicationContextType
);

const useApplicationContext = (): ApplicationContextType => {
  const context = useContext(ApplicationContext);
  if (!context) {
    throw new Error("Application context outside of provider");
  }
  return context;
};

const ApplicationContextProvider: FC = (props) => {
  const [application, setApplicationContext] = useState<Application>(
    null as unknown as Application
  );

  const value = useMemo(
    () => ({ application, setApplicationContext }),
    [application]
  );
  return <ApplicationContext.Provider value={value} {...props} />;
};

export {
  useApplicationContext,
  ApplicationContext,
  ApplicationContextProvider,
};
export type { ApplicationContextType, Application };
