import { DateRange } from "../../types/DateRange";
import {
  Exercise,
  TrainingDay,
  TrainingDayList,
} from "../../types/TrainingClientInterface";
import {
  DateTimeUtil,
  SportActivitiesInterface,
  TrainingInterface,
} from "@almservices-cl/coach-app-shared-components";
import map from "../utils/async/map";
import { getWeightUnit } from "../utils/units/unitOfWeightUtils";
import { DateValue, DateVOPersisted } from "../DateVO";
import Member from "../../types/Member";
import {
  addSportActivitiesToTrainingDayPreview,
  countSportActivitiesInDateRange,
} from "../utils/sport-activities";

export default class TrainingService {
  constructor(
    private trainingClient: TrainingInterface,
    private sportActivityClient: SportActivitiesInterface
  ) {}

  getTrainingByDayRange = async (
    user: Member,
    dateRange: DateRange
  ): Promise<TrainingDayList> => {
    const { sportActivitiesPerDate } =
      await this.sportActivityClient.fetchSportActivitiesPerDate(user.id, {
        from: DateVOPersisted(dateRange.from).persistYMD(),
        to: DateVOPersisted(dateRange.to).persistYMD(),
      });
    const { trainingsPerWeek, days } = await this.trainingClient.getRange(
      user.id,
      {
        from: DateVOPersisted(dateRange.from).persist(),
        to: DateVOPersisted(dateRange.to).persist(),
      }
    );

    return {
      trainingsPerWeek,
      days: addSportActivitiesToTrainingDayPreview(
        days,
        sportActivitiesPerDate
      ),
      sportActivitiesInRange: countSportActivitiesInDateRange(
        sportActivitiesPerDate
      ),
    };
  };

  getTrainingByDate = async (
    user: Member,
    date: DateValue
  ): Promise<TrainingDay | null> => {
    const day = await this.trainingClient.getDate(
      user.id,
      date.persist(),
      getWeightUnit()
    );

    const sportActivities = await this.sportActivityClient.fetchSportActivities(
      user.id,
      { from: date.persistYMD(), to: date.persistYMD() }
    );

    if (day === null) {
      return null;
    }

    return {
      ...day,
      dayType: day.dayType,
      training: {
        ...day.training,
        exercises: await map(day.training.exercises, async (exercise) => {
          const exe = exercise as Exercise;
          return {
            ...(exe as Exercise),
            image: await this.fetchExerciseImage(exe.id),
            historyOfProgress: exe.historyOfProgress
              .slice(0, 2)
              .reverse()
              .map((historyItem) => {
                return {
                  ...historyItem,
                  date: DateTimeUtil.prettyDate(historyItem.date),
                };
              }),
          };
        }),
      },
      sportActivities: sportActivities[0]?.sportActivities || [],
    };
  };

  fetchExerciseImage = async (exerciseId: string): Promise<string | null> => {
    try {
      return await this.trainingClient.getExercisePhoto(exerciseId);
    } catch (e) {
      return null;
    }
  };
}
