import { useState, useEffect, FC } from "react";
import { LoginServiceInterface } from "../../business-logic/services/LoginService";
import { useHistory } from "react-router-dom";
import buildDashboardUrl from "../../business-logic/utils/buildDashboardUrl";
import { FormTemplate, LoginForm } from "@almservices-cl/storybook-admin";
import loginTranslations from "../../locales/translations/loginTranslations";
import { AuthException } from "@almservices-cl/coach-app-shared-components";
import getErrorMessageFromExceptionType, {
  CustomExceptionObject,
} from "../../business-logic/utils/error-handlers/getErrorMessageFromExceptionType";
import AllFieldsRequiredException from "../../types/Exceptions/AllFieldsRequiredException";
import AdminHeader from "../screens/AdminHeader";
import {
  isOffline as isOfflineCheck,
  NetworkObserver,
  observeNetworkStatus,
  unobserveNetworkStatus,
} from "../../networkStatus";
import { FormValues } from "../../types/FormValues";
import cache from "../../cache-layer/Cache";
import CoachService from "../../business-logic/services/CoachService";
import i18n from "../../i18n";
import { useLogoutContext } from "../contexts/logout.context";
import { useApplicationContext } from "../contexts/application.context";
import { getDefaultAppContext, Role } from "../../business-logic/utils/pmp";

interface Props {
  loginService: LoginServiceInterface;
  userDataProvider: CoachService;
}

const exceptionsMap: CustomExceptionObject[] = [
  {
    exceptionType: AllFieldsRequiredException,
    customErrorMessage: loginTranslations.errors.allFieldsRequired,
  },
  {
    exceptionType: AuthException,
    customErrorMessage: loginTranslations.errors.verification,
  },
];

const checkEnv = async (): Promise<void> => {
  const env = await cache.getValue("env");
  if (process.env.NODE_ENV !== "production" && !env) {
    window.location.reload();
  }
};

const LoginController: FC<Props> = ({ loginService, userDataProvider }) => {
  const history = useHistory();
  const logoutCtx = useLogoutContext();
  const appCtx = useApplicationContext();

  const [error, setError] = useState<string>();
  const [isOffline, setIsOffline] = useState<boolean>(isOfflineCheck());

  useEffect(() => {
    checkEnv();
    checkAuth();
    observeNetworkStatus(subscribeNetworkStatus);
    return (): void => {
      unobserveNetworkStatus(subscribeNetworkStatus);
    };
  }, []);

  const subscribeNetworkStatus: NetworkObserver = (mode): void => {
    setIsOffline(mode === "offline");
  };

  const setContextsAndRedirectToDashboard = async (): Promise<void> => {
    const coach = await userDataProvider.getCoachDetails();
    appCtx.setApplicationContext(getDefaultAppContext(coach.roles as Role[]));
    logoutCtx.startTrackingUserActivity();
    history.push(
      buildDashboardUrl(
        "member-activity",
        getDefaultAppContext(coach.roles as Role[])
      )
    );
  };

  const checkAuth = async (): Promise<void> => {
    const isAuthenticated = await userDataProvider.isUserAuthenticated();
    if (!isAuthenticated) {
      return;
    }
    await setContextsAndRedirectToDashboard();
  };

  const setErrorToState = (error: string): void => {
    setError(error);
    clearError();
  };

  const clearError = (): void => {
    setTimeout(() => setError(""), 2500);
  };

  const handleResetPassword = (): void => {
    history.push("/password-reset");
  };

  const handleSignIn = async ({
    email,
    password,
  }: FormValues): Promise<void> => {
    try {
      await loginService.signInUser(email, password);
      await setContextsAndRedirectToDashboard();
    } catch (e) {
      const error = getErrorMessageFromExceptionType(exceptionsMap, e);
      setErrorToState(error);
    }
  };

  return (
    <FormTemplate
      offlineMessage={i18n.t("offlineBar.notification")}
      body={
        <LoginForm
          onSubmit={handleSignIn}
          onLinkClick={handleResetPassword}
          disabled={isOffline}
          error={error}
        />
      }
      topBar={<AdminHeader coach={null} loginService={loginService} />}
    />
  );
};

export default LoginController;
