import {
  HistoryInterface,
  HistoryItems,
} from "@almservices-cl/coach-app-shared-components";
import { adaptEvent } from "../utils/history/history";
import { getNutrientUnit } from "../utils/units/unitOfWeightUtils";

export interface HistoryServiceInterface {
  getHistory(memberId: string): Promise<HistoryItems>;
  getHistoryAfter(memberId: string, eventId: string): Promise<HistoryItems>;
}

export default class HistoryService implements HistoryServiceInterface {
  constructor(private readonly client: HistoryInterface) {}

  getHistory = async (memberId: string): Promise<HistoryItems> => {
    const { items } = await this.client.getHistory(memberId, getNutrientUnit());
    return {
      items: items.map(adaptEvent),
    };
  };
  getHistoryAfter = async (
    memberId: string,
    eventId: string
  ): Promise<HistoryItems> => {
    const { items } = await this.client.getHistoryAfter(
      memberId,
      eventId,
      getNutrientUnit()
    );
    return {
      items: items.map(adaptEvent),
    };
  };
}
