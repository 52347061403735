export default async <T, U>(
  items: T[],
  processor: (item: T) => Promise<U>
): Promise<U[]> => {
  const ret = [];

  for (let i = 0; i < items.length; ++i) {
    ret.push(await processor(items[i]));
  }

  return ret;
};
