import PouchDB from "pouchdb";
import {
  Observable,
  AbstractCache,
} from "@almservices-cl/coach-app-shared-components";

const DB_NAME = "coaching-dashboard-application2";
class Cache extends AbstractCache {
  private db;

  private stack: (() => Promise<void>)[] = [];
  private state = "idle";

  constructor() {
    super();
    this.db = this.create();
  }

  create = (): PouchDB.Database => {
    // eslint-disable-next-line
    return new PouchDB(DB_NAME, { auto_compaction: true });
  };

  getValue<T>(key: string): Observable<T> {
    const o = new Observable<T>(key);
    this.add(async (): Promise<void> => {
      try {
        // TODO: TS - type casting callback to any due to type inconsistencies
        this.db.get(key, ((error: any, doc: any) => {
          if (error) {
            if (error.status === 404) {
              o.setValue(null);
            }
            return;
          }

          o.setValue(doc.value);
        }) as any);
      } catch (e) {
        const err = new Error("Cache Error");
        // @ts-ignore
        err.e = e;
        o.setError(err);
      }

      try {
        await o.asPromise();
      } catch (e) {
        // ignored
      }
    });

    return o;
  }

  setValue<T>(key: string, value: T): Observable<void> {
    const o = new Observable<void>(key);

    // TODO: TS - type casting callback to any due to type inconsistencies
    this.add(async () => {
      this.db.get(key, ((_error: any, doc: any) => {
        this.put(o, key, value, doc);
      }) as any);

      await o.asPromise();
    });

    return o;
  }

  private add = (task: () => Promise<void>): void => {
    this.stack.push(task);
    this.run();
  };

  private run = async (): Promise<void> => {
    if (this.state === "running") {
      return;
    }
    this.state = "running";
    while (this.stack.length !== 0) {
      const task = this.stack[0];
      try {
        await task();
      } catch (e) {
        this.stack = [];
        this.state = "idle";
        throw e;
      }

      this.stack = this.stack.slice(1);
    }
    this.state = "idle";
  };

  clear = async (): Promise<void> => {
    // TODO: TS - type casting due to type inconsistencies
    await this.db.destroy(DB_NAME as PouchDB.Core.Options);
    // it has to be recreated
    this.db = this.create();
  };

  private put<T>(
    o: Observable<void>,
    key: string,
    value: T,
    doc: { _rev: number } | null
  ): void {
    const toPut = {
      _rev: undefined as any,
      _id: key,
      value,
    };

    if (doc) {
      toPut._rev = doc._rev;
    }

    this.db.put(toPut).then(
      () => o.setValue(),
      (e: unknown) => {
        if (e instanceof Error) {
          o.setError(e);
        }
      }
    );
  }
}

const cache = new Cache();
export default cache;
