import {
  TrainingWeekDay,
  TrainingWeekDayExtended,
} from "../../../types/TrainingClientInterface";
import { WeekDayType } from "@almservices-cl/coach-app-shared-components";
import { days } from "../../../locales/translations/timeTranslations";
import i18n from "../../../i18n";
import { TrainingWeekItem } from "@almservices-cl/storybook-admin";

const trainingsDayRatings = (prevRating: number, nextRating: number): number =>
  prevRating + nextRating;

const filterTrainingsDay = <T extends { dayType: WeekDayType }>(
  trainingDay: T
): boolean => trainingDay.dayType === WeekDayType.TRAINING_DAY;

const trainingDaysToRating = (trainingDay: TrainingWeekDay): number | null =>
  trainingDay.rate;

const removeNullableRatings = (rating: number | null): boolean =>
  rating !== null;

const getAverageRatingFromTrainingDays = (
  trainingsPreview: TrainingWeekDay[]
): string => {
  if (!trainingsPreview || trainingsPreview.length === 0) return "-";

  const prepareRatings = trainingsPreview
    .filter(filterTrainingsDay)
    .map(trainingDaysToRating)
    .filter(removeNullableRatings) as number[];

  if (prepareRatings.length === 0) return "-";

  const averageRating = prepareRatings.reduce(trainingsDayRatings, 0);
  if (!averageRating) return averageRating?.toString();

  return Math.round((averageRating * 10) / prepareRatings.length) / 10 + "";
};

const isTrainingDone = <T extends { isDone?: boolean }>(training: T): boolean =>
  Boolean(training.isDone);

const getCompletedTrainings = <
  T extends { isDone?: boolean; dayType: WeekDayType }
>(
  trainingsPreview: T[]
): string => {
  if (!trainingsPreview || trainingsPreview.length === 0) return "";

  const trainingsDays = trainingsPreview.filter(filterTrainingsDay);
  const doneTrainings = trainingsDays.filter(isTrainingDone);

  return `${doneTrainings.length}/${trainingsDays.length}`;
};

const checkTrainingRating = (rating?: number | null): string => {
  if (rating === 0) return "0";
  return rating ? rating.toString() : "-";
};

const resolveTrainingDayText = (
  dayType: "REST" | "RECOVERY" | "TRAINING"
): string => {
  switch (dayType) {
    case "REST": {
      return i18n.t("training.dayType.recovery");
    }
    case "RECOVERY": {
      return i18n.t("training.dayType.active");
    }
    case "TRAINING": {
      return i18n.t("training.dayType.trainingDay");
    }
    default: {
      return "default";
    }
  }
};

const createTrainingWeekItemData = (
  trainingsPreview: TrainingWeekDayExtended[],
  onItemClick: (date: string) => void
): TrainingWeekItem[] => {
  return trainingsPreview.map(
    (trainingPreview): TrainingWeekItem => ({
      label: `${days[trainingPreview.day]} - ${resolveTrainingDayText(
        trainingPreview.dayType
      )}`,
      value: trainingPreview.isDone,
      rating: checkTrainingRating(trainingPreview.rate),
      isValueVisible: trainingPreview.dayType === "TRAINING",
      onClick: () => onItemClick(trainingPreview.date),
      sportActivities: trainingPreview.sportActivities,
    })
  );
};

export {
  getAverageRatingFromTrainingDays,
  filterTrainingsDay,
  getCompletedTrainings,
  createTrainingWeekItemData,
};
