import { CoachInterface } from "@almservices-cl/coach-app-shared-components";
import CoachPersonalInfo from "../../types/CoachPersonalInfo";
import { getTimezone } from "../utils/units/timezoneUtils";
import {
  TimeZoneOffsetHours,
  TimeZoneOffsetMinutes,
} from "../../types/TimeZoneOffset";
import { CoachRating } from "../../types/CoachRating";
import {
  TimeZone,
  AuthException,
  AuthInterface,
} from "@almservices-cl/coach-app-shared-components";
import { setUser } from "@sentry/react";
import cache from "../../cache-layer/Cache";
import {
  MemberMonthlyOverview,
  MonthNumber,
} from "../../types/CoachMonthlyOverviewTypes";

export interface CoachServiceInterface {
  isUserAuthenticated: () => Promise<boolean>;
  getUserTimeZone: () => TimeZone;
  fetchRatings: () => Promise<CoachRating[]>;
  fetchMonthlyCustomerOverview(
    year: number,
    monthNumber: MonthNumber
  ): Promise<MemberMonthlyOverview[]>;
}

export default class CoachService implements CoachServiceInterface {
  constructor(
    private coachClient: CoachInterface,
    private authClient: AuthInterface
  ) {}

  isUserAuthenticated = async (): Promise<boolean> => {
    try {
      const isAuth = await this.authClient.isAuth();
      if (isAuth) {
        await this.setTrackingInfo();
      }
      return isAuth;
    } catch (e) {
      return false;
    }
  };

  getUserTimeZone = (): TimeZone => {
    const [hours, minutes] = getTimezone().split(":");
    return new TimeZone(
      parseInt(hours) as TimeZoneOffsetHours,
      parseInt(minutes) as TimeZoneOffsetMinutes,
      true // TODO: when implementing other time zones please carefully take a look at it
    );
  };

  getCoachDetails = async (): Promise<CoachPersonalInfo> => {
    const isAuth = await this.authClient.isAuth();
    if (isAuth) {
      return this.coachClient.getPersonalInformation();
    }

    throw new AuthException();
  };

  fetchRatings = (): Promise<CoachRating[]> => {
    const timezone = this.getUserTimeZone();
    return this.coachClient.getRatings(timezone);
  };

  private setTrackingInfo = async (): Promise<void> => {
    const coachDetails = await this.getCoachDetails();

    setUser({
      email: coachDetails.email,
      id: coachDetails.id,
      env: cache.getValue("env"),
    });
  };

  fetchMonthlyCustomerOverview = async (
    year: number,
    monthNumber: MonthNumber
  ): Promise<MemberMonthlyOverview[]> => {
    return this.coachClient.getMonthlyCustomerOverview(year, monthNumber);
  };
}
