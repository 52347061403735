import { MemberListServiceInterface } from "./MemberListService";
import mapMessagesToChatMessages from "../utils/chat/mapMessagesToChatMessages";
import { getMemberName } from "../utils/chat/getMemberNameFromMembersById";
import {
  ChatMessage,
  MessagesData,
  SendAttachmentResponse,
} from "../../types/ChatMessage";
import Message from "../../types/Message";
import { getEnterToSend, setEnterToSend } from "../utils/chat/chatUtils";
import CoachService from "./CoachService";
import { ChatInterface } from "@almservices-cl/coach-app-shared-components";
import Member from "../../types/Member";
import CoachPersonalInfo from "../../types/CoachPersonalInfo";
import { readAndCompressImage } from "browser-image-resizer";

export const makeChatMessage = (
  coachDetails: CoachPersonalInfo,
  message: Message
): ChatMessage => {
  return {
    userId: coachDetails.id,
    username: coachDetails.name,
    dateTime: message.message.isoDateTime,
    messageParts: message.message.messageParts,
    messageId: message.message.id as string,
    authorDisplayName: message.authorDisplayName,
    attachment: message.message.attachment,
  };
};

export interface ChatServiceInterface {
  fetchChatMessagesAfter: (
    member: Member,
    coachDetail: CoachPersonalInfo
  ) => Promise<MessagesData>;
  sendMessage: (member: Member, content: string) => Promise<ChatMessage>;
  getMoreMessagesBefore: (
    member: Member,
    lastMessageId: string
  ) => Promise<MessagesData>;
  markUserAsRead: (member: Member) => Promise<void>;
  fetchMemberEmailByMemberId: (member: Member) => Promise<string>;
  fetchEnterToSendPreference: () => Promise<boolean>;
  setEnterToSendPreference: (enterToSend: boolean) => Promise<void>;
  sendAttachment(
    file: File,
    caption: string,
    memberId: string,
    galleryId: string
  ): Promise<SendAttachmentResponse>;
}

export default class ChatService implements ChatServiceInterface {
  constructor(
    private userDataProvider: CoachService,
    private chatClient: ChatInterface,
    private memberListService: MemberListServiceInterface,
    private application: "PBP" | "PMP"
  ) {}

  fetchChatMessagesAfter = async (
    member: Member,
    coachDetail: CoachPersonalInfo
  ): Promise<MessagesData> => {
    const memberName = getMemberName(member);
    const timeZone = this.userDataProvider.getUserTimeZone();
    const { isMoreMessage, messages } = await this.chatClient.after(
      this.application,
      timeZone,
      null,
      member.id
    );

    return {
      isMoreMessage,
      messages: mapMessagesToChatMessages(
        messages,
        member.id,
        coachDetail.name,
        memberName
      ),
    };
  };

  sendMessage = async (
    member: Member,
    content: string
  ): Promise<ChatMessage> => {
    const timezone = this.userDataProvider.getUserTimeZone();
    const coachDetails = await this.userDataProvider.getCoachDetails();
    const message: Message = this.chatClient.mapMessage(
      coachDetails.id,
      "",
      content,
      timezone
    );
    await this.chatClient.send(this.application, content, timezone, member.id);
    return makeChatMessage(coachDetails, message);
  };

  getMoreMessagesBefore = async (
    member: Member,
    lastMessageId: string
  ): Promise<MessagesData> => {
    const timezone = this.userDataProvider.getUserTimeZone();
    const { messages, isMoreMessage } = await this.chatClient.before(
      this.application,
      lastMessageId,
      timezone,
      member.id
    );
    const coachDetail = await this.userDataProvider.getCoachDetails();
    const memberName = getMemberName(member);

    return {
      isMoreMessage,
      messages: mapMessagesToChatMessages(
        messages,
        member.id,
        coachDetail.name,
        memberName
      ),
    };
  };

  markUserAsRead = async (member: Member): Promise<void> =>
    this.memberListService.markUserAsRead(member);

  fetchMemberEmailByMemberId = async (member: Member): Promise<string> =>
    member.email;

  fetchEnterToSendPreference = async (): Promise<boolean> => getEnterToSend();

  setEnterToSendPreference = async (enterToSend: boolean): Promise<void> =>
    setEnterToSend(enterToSend);

  sendAttachment = async (
    file: File,
    caption: string,
    memberId: string,
    galleryId: string
  ): Promise<SendAttachmentResponse> => {
    return this.chatClient.sendAttachment(
      this.application,
      await this.fixAttachmentSize(file),
      caption,
      memberId,
      galleryId
    );
  };

  private fixAttachmentSize = async (attachment: File): Promise<File> => {
    if (attachment.size >= 16_000_000) {
      const processedFile = await readAndCompressImage(attachment as File, {
        quality: 0.85,
        maxHeight: 4096,
        maxWidth: 4096,
        mimeType: attachment.type,
      });
      return processedFile as File;
    }
    return attachment;
  };
}
