import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "@almservices-cl/storybook-admin";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import AppExec from "./AppExec";
import registerServiceWorker from "./registerServiceWorker";
import { GlobalStyles, ResetStyle } from "@almservices-cl/storybook-admin";
import "./sentryConfig";

const root = document.getElementById("root");

//
// This is fix for styled components bug
// https://github.com/styled-components/styled-components/issues/1593
// https://github.com/dothq/browser/commit/1964d4a1ac9eb853d8547e2df77dfa23802e6a0b
//
const style = document.createElement("style");
style.textContent = `
  @font-face {
    font-family: 'ProximaNova';src: url('/font/proximanova/337B8B_2_0.eot');src: url('/font/proximanova/337B8B_2_0.eot?#iefix') format('embedded-opentype'),url('/font/proximanova/337B8B_2_0.woff2') format('woff2'),url('/font/proximanova/337B8B_2_0.woff') format('woff'),url('/font/proximanova/337B8B_2_0.ttf') format('truetype');
    font-weight: bold;
  }
  @font-face {
    font-family: 'ProximaNova';src: url('/font/proximanova/337B8B_5_0.eot');src: url('/font/proximanova/337B8B_5_0.eot?#iefix') format('embedded-opentype'),url('/font/proximanova/337B8B_5_0.woff2') format('woff2'),url('/font/proximanova/337B8B_5_0.woff') format('woff'),url('/font/proximanova/337B8B_5_0.ttf') format('truetype');
    font-weight: normal;
  }
  @font-face {
    font-family: 'ProximaNova';src: url('/font/proximanova/337B8B_8_0.eot');src: url('/font/proximanova/337B8B_8_0.eot?#iefix') format('embedded-opentype'),url('/font/proximanova/337B8B_8_0.woff2') format('woff2'),url('/font/proximanova/337B8B_8_0.woff') format('woff'),url('/font/proximanova/337B8B_8_0.ttf') format('truetype');
    font-weight: 500;
  }
  `;
document.head.appendChild(style);

if (root !== null) {
  ReactDOM.render(
    <BrowserRouter>
      <ThemeProvider theme={defaultTheme}>
        <ResetStyle />
        <GlobalStyles />
        <AppExec />
      </ThemeProvider>
    </BrowserRouter>,
    root
  );
}

registerServiceWorker();
