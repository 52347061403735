import i18n from "../../../i18n";
import { Environment } from "../../../types/Environment";
import { StyledEnvironmentList } from "../../styledComponents";

interface Props {
  onSetEnvironment: (option: string) => void;
  environments: Environment[];
  predicateVPN: boolean;
}

export default (props: Props): JSX.Element => (
  <StyledEnvironmentList
    setEnvironment={props.onSetEnvironment}
    buttonLabel={i18n.t("environment.submitButtonLabel")}
    defaultSelectValue={i18n.t("environment.defaultSelectValueLabel")}
    environments={props.environments}
    predicateVPN={props.predicateVPN}
  />
);
