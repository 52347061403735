import LoginService from "../business-logic/services/LoginService";
import ChatService from "../business-logic/services/ChatService";
import CapacityService, {
  CapacityServiceInterface,
} from "../business-logic/services/CapacityService";
import {
  AuthInterface,
  BehaviourInterface,
  ChatInterface,
  CoachInterface,
  CustomerInterface,
  NutritionInterface,
  RecoveryInterface,
  JournalInterface,
  TrainingInterface,
  ResultsInterface,
  TraineeList,
  HistoryInterface,
  SportActivitiesInterface,
} from "@almservices-cl/coach-app-shared-components";
import MemberListService from "../business-logic/services/MemberListService";
import NutritionService from "../business-logic/services/NutritionService";
import TrainingService from "../business-logic/services/TrainingService";
import BehaviorService from "../business-logic/services/BehaviorService";
import RecoveryService from "../business-logic/services/RecoveryService";
import MeasurementService from "../business-logic/services/MeasurementService";
import CalorieBudgetService from "../business-logic/services/CalorieBudgetService";
import CoachService from "../business-logic/services/CoachService";
import DayTypeService from "../business-logic/services/DayTypeService";
import OverviewService from "../business-logic/services/OverviewService";
import TraineeListService from "../business-logic/services/TraineeListService";
import HistoryService, {
  HistoryServiceInterface,
} from "../business-logic/services/HistoryService";
import JournalService from "src/business-logic/services/JournalService";

const clients = {
  AUTH_CLIENT: "client",
  CUSTOMER_CLIENT: "customerClient",
  CHAT_CLIENT: "chatClient",
  COACH_CLIENT: "coachClient",
  NUTRITION_CLIENT: "nutritionClient",
  TRAINING_CLIENT: "trainingClient",
  BEHAVIOUR_CLIENT: "behaviorClient",
  RECOVERY_CLIENT: "recoveryClient",
  JOURNAL_CLIENT: "journalClient",
  MEASUREMENT_CLIENT: "measurementClient",
  TRAINEE_LIST_CLIENT: "traineeListClient",
  HISTORY_CLIENT: "historyClient",
  SPORT_ACTIVITY_CLIENT: "sportActivityClient",
};

const services = {
  LOGIN_SERVICE: "loginService",
  MEMBER_LIST_SERVICE: "memberListService",
  TRAINEE_LIST_SERVICE: "traineeListService",
  CHAT_SERVICE_PBP: "chatServicePBP",
  CHAT_SERVICE_PMP: "chatServicePMP",
  CAPACITY_SERVICE: "capacityService",
  COACH_SERVICE: "coachService",
  NUTRITION_SERVICE: "nutritionService",
  TRAINING_SERVICE: "trainingService",
  BEHAVIOR_SERVICE: "behaviorService",
  RECOVERY_SERVICE: "recoveryService",
  JOURNAL_SERVICE: "journalService",
  MEASUREMENT_SERVICE: "measurementService",
  CALORIE_BUDGET_SERVICE: "calorieBudgetService",
  DAY_TYPE_SERVICE: "dayTypeService",
  OVERVIEW_SERVICE: "overviewService",
  HISTORY_SERVICE: "historyService",
};

const applications = {
  PBP: "PBP",
  PMP: "PMP",
};

export { clients, services };

// TODO: use @almservices-cl/di
class Container {
  services: Map<string, any> = new Map();
  instances: Map<string, any> = new Map();

  constructor(
    client: AuthInterface,
    customerClient: CustomerInterface,
    chatClient: ChatInterface,
    coachClient: CoachInterface,
    nutritionClient: NutritionInterface,
    trainingClient: TrainingInterface,
    behaviourClient: BehaviourInterface,
    recoveryClient: RecoveryInterface,
    measurementClient: ResultsInterface,
    traineeListClient: TraineeList,
    historyClient: HistoryInterface,
    sportActivities: SportActivitiesInterface,
    journalClient: JournalInterface
  ) {
    this.init(
      client,
      customerClient,
      chatClient,
      coachClient,
      nutritionClient,
      trainingClient,
      behaviourClient,
      recoveryClient,
      measurementClient,
      traineeListClient,
      historyClient,
      sportActivities,
      journalClient
    );
  }

  init(
    client: AuthInterface,
    customerClient: CustomerInterface,
    chatClient: ChatInterface,
    coachClient: CoachInterface,
    nutritionClient: NutritionInterface,
    trainingClient: TrainingInterface,
    behaviourClient: BehaviourInterface,
    recoveryClient: RecoveryInterface,
    measurementClient: ResultsInterface,
    traineeListClient: TraineeList,
    historyClient: HistoryInterface,
    sportActivity: SportActivitiesInterface,
    journalClient: JournalInterface
  ): void {
    this.register(applications.PBP, applications.PBP);
    this.register(applications.PMP, applications.PMP);
    this.register(clients.AUTH_CLIENT, client);
    this.register(clients.CUSTOMER_CLIENT, customerClient);
    this.register(clients.CHAT_CLIENT, chatClient);
    this.register(clients.COACH_CLIENT, coachClient);
    this.register(clients.NUTRITION_CLIENT, nutritionClient);
    this.register(clients.TRAINING_CLIENT, trainingClient);
    this.register(clients.BEHAVIOUR_CLIENT, behaviourClient);
    this.register(clients.RECOVERY_CLIENT, recoveryClient);
    this.register(clients.JOURNAL_CLIENT, journalClient);
    this.register(clients.MEASUREMENT_CLIENT, measurementClient);
    this.register(clients.TRAINEE_LIST_CLIENT, traineeListClient);
    this.register(clients.HISTORY_CLIENT, historyClient);
    this.register(clients.SPORT_ACTIVITY_CLIENT, sportActivity);
    this.register(services.LOGIN_SERVICE, LoginService, [clients.AUTH_CLIENT]);
    this.register(services.MEMBER_LIST_SERVICE, MemberListService, [
      clients.CUSTOMER_CLIENT,
      services.COACH_SERVICE,
      clients.NUTRITION_CLIENT,
    ]);
    this.register(services.TRAINEE_LIST_SERVICE, TraineeListService, [
      clients.TRAINEE_LIST_CLIENT,
    ]);
    this.register(services.CHAT_SERVICE_PBP, ChatService, [
      services.COACH_SERVICE,
      clients.CHAT_CLIENT,
      services.MEMBER_LIST_SERVICE,
      applications.PBP,
    ]);
    this.register(services.CHAT_SERVICE_PMP, ChatService, [
      services.COACH_SERVICE,
      clients.CHAT_CLIENT,
      services.MEMBER_LIST_SERVICE,
      applications.PMP,
    ]);
    this.register(services.CAPACITY_SERVICE, CapacityService, [
      clients.COACH_CLIENT,
    ]);
    this.register(services.NUTRITION_SERVICE, NutritionService, [
      clients.NUTRITION_CLIENT,
      clients.AUTH_CLIENT,
    ]);
    this.register(services.TRAINING_SERVICE, TrainingService, [
      clients.TRAINING_CLIENT,
      clients.SPORT_ACTIVITY_CLIENT,
    ]);
    this.register(services.BEHAVIOR_SERVICE, BehaviorService, [
      clients.BEHAVIOUR_CLIENT,
    ]);
    this.register(services.RECOVERY_SERVICE, RecoveryService, [
      clients.RECOVERY_CLIENT,
    ]);
    this.register(services.JOURNAL_SERVICE, JournalService, [
      clients.JOURNAL_CLIENT,
    ]);
    this.register(services.MEASUREMENT_SERVICE, MeasurementService, [
      clients.MEASUREMENT_CLIENT,
    ]);
    this.register(services.CALORIE_BUDGET_SERVICE, CalorieBudgetService, [
      clients.CUSTOMER_CLIENT,
      clients.NUTRITION_CLIENT,
      services.COACH_SERVICE,
    ]);
    this.register(services.COACH_SERVICE, CoachService, [
      clients.COACH_CLIENT,
      clients.AUTH_CLIENT,
    ]);
    this.register(services.DAY_TYPE_SERVICE, DayTypeService, [
      clients.CUSTOMER_CLIENT,
    ]);
    this.register(services.OVERVIEW_SERVICE, OverviewService, [
      clients.BEHAVIOUR_CLIENT,
      clients.NUTRITION_CLIENT,
      clients.TRAINING_CLIENT,
      clients.RECOVERY_CLIENT,
      clients.MEASUREMENT_CLIENT,
      clients.SPORT_ACTIVITY_CLIENT,
    ]);
    this.register(services.HISTORY_SERVICE, HistoryService, [
      clients.HISTORY_CLIENT,
    ]);
  }

  register(name: string, definition: any, dependencies?: string[]): void {
    this.services.set(name, {
      definition,
      dependencies,
    });
  }

  get(serviceName: string): any {
    const serviceDefinition = this.services.get(serviceName);

    if (serviceDefinition === undefined) {
      throw new Error(`Container does not contain "${serviceName}"`);
    }

    if (Container.isClass(serviceDefinition.definition)) {
      if (!this.instances.has(serviceName)) {
        this.instances.set(serviceName, this.createInstance(serviceDefinition));
      }

      return this.instances.get(serviceName);
    }

    return serviceDefinition.definition;
  }

  getResolvedDependencies(service: any): any {
    let classDependencies = [];
    if (service.dependencies) {
      classDependencies = service.dependencies.map((dep: string): any =>
        this.get(dep)
      );
    }
    return classDependencies;
  }

  createInstance(service: any): any {
    // eslint-disable-next-line new-cap
    return new service.definition(...this.getResolvedDependencies(service));
  }

  static isClass(definition: any): boolean {
    return typeof definition === "function";
  }
  getLoginService(): LoginService {
    return this.get(services.LOGIN_SERVICE);
  }

  getMemberListService(): MemberListService {
    return this.get(services.MEMBER_LIST_SERVICE);
  }

  getTraineeListService(): TraineeListService {
    return this.get(services.TRAINEE_LIST_SERVICE);
  }

  getChatService(application: "PBP" | "PMP"): ChatService {
    return this.get(
      application === "PBP"
        ? services.CHAT_SERVICE_PBP
        : services.CHAT_SERVICE_PMP
    );
  }

  getCapacityService(): CapacityServiceInterface {
    return this.get(services.CAPACITY_SERVICE);
  }

  getNutritionService(): NutritionService {
    return this.get(services.NUTRITION_SERVICE);
  }

  getTrainingService(): TrainingService {
    return this.get(services.TRAINING_SERVICE);
  }

  getDayTypeService(): DayTypeService {
    return this.get(services.DAY_TYPE_SERVICE);
  }

  getBehaviorService(): BehaviorService {
    return this.get(services.BEHAVIOR_SERVICE);
  }

  getRecoveryService(): RecoveryService {
    return this.get(services.RECOVERY_SERVICE);
  }

  getJournalService(): JournalService {
    return this.get(services.JOURNAL_SERVICE);
  }

  getMeasurementService(): MeasurementService {
    return this.get(services.MEASUREMENT_SERVICE);
  }

  getCalorieBudgetService(): CalorieBudgetService {
    return this.get(services.CALORIE_BUDGET_SERVICE);
  }

  getCoachService(): CoachService {
    return this.get(services.COACH_SERVICE);
  }

  getOverviewService(): OverviewService {
    return this.get(services.OVERVIEW_SERVICE);
  }
  getHistoryService(): HistoryServiceInterface {
    return this.get(services.HISTORY_SERVICE);
  }
}

export default Container;
