import InvalidInputException from "./InvalidInputException";

// TODO: Piotr change reason to obligatory
export default abstract class FormException extends InvalidInputException {
  constructor(reason?: string) {
    if (!reason) {
      super("Invalid input");
    } else {
      super(reason);
    }
  }
}
