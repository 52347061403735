import {
  TraineeList,
  ObservableInterface,
  Observable,
  Trainee,
} from "@almservices-cl/coach-app-shared-components";
import Member from "../../types/Member";

export default class TraineeListService {
  constructor(private readonly traineeList: TraineeList) {}

  getTraineeList = (): ObservableInterface<Member[]> => {
    const observable = new Observable<Member[]>("trainee_list_pmp");

    this.traineeList
      .getTraineeList("PMP")
      .onSuccess((trainees: Trainee[] | null): void => {
        observable.setValue(
          //  TODO: TS - Incompatible types with shared-componensts (observable from shared allows null)
          (trainees as Trainee[]).map(
            (trainee: Trainee): Member =>
              //  TODO: TS - Incompatible types with shared-componensts (type casting to any due to many fields types inconsistencies)
              ({
                id: trainee.id,
                notifications: {},
                lastActive: null,
                absoluteDay: 0,
                address: {
                  city: "",
                  localeNumber: "",
                  postalCode: "",
                  street: "",
                },
                email: trainee.member.email,
                firstName: trainee.member.firstName,
                lastName: trainee.member.lastName,
                galleryId: "",
                code: {
                  label: null,
                  isIndividualCode: false,
                  trainingCode: null,
                },
                birthDate: "2021-12-31",
                eventsNotification: {
                  daysToBirthday: 100,
                  daysToSubscriptionsEnd: 100,
                  daysToPlanEnd: 100,
                  hasNewCoach: false,
                  hasRejoined: false,
                  hasUpgraded: false,
                  isNew: false,
                  isProlonger: false,
                },
                isNew: false,
                isIntakeDone: false,
                lastActiveFromNow: null,
                phoneNumber: null,
                method: "",
                period: {
                  dateOfPeriodStart: "",
                  dateOfPeriodEnd: "",
                  length: 6,
                },
                subscription: null,
                plan: {
                  name: "Personal Mind Plan",
                },
                trainingLocation: {
                  placeName: "",
                  gymName: "",
                },
                responseDeadlineHours: null,
              } as any)
          )
        );
      });

    return observable;
  };
}
