import {
  BehaviourInterface,
  NutritionInterface,
  TrainingInterface,
  RecoveryInterface,
  ResultsInterface,
  SportActivitiesInterface,
} from "@almservices-cl/coach-app-shared-components";
import { DateRange } from "../../types/DateRange";
import {
  BehaviourOverviewMap,
  createBehaviourWeekOverviewMap,
} from "../utils/member-activity-weekoverview/createBehaviourWeekOverviewMap";
import { getWeightUnit } from "../utils/units/unitOfWeightUtils";
import {
  createFactsMapFromCalorieBudget,
  nutritionDaysToMap,
} from "../utils/member-activity-weekoverview/nutritionOverviewUtils";
import {
  NutritionAverageFactsMap,
  NutritionOverviewMap,
} from "../../types/NutritionClientInterface";
import {
  NutritionFactsMap,
  TrainingWeekItem,
} from "@almservices-cl/storybook-admin";
import {
  createTrainingOverview,
  TrainingOverview,
} from "../utils/member-activity-weekoverview/trainingOverview";
import { ProgressOverviewMap } from "../../types/RecoveryProgress";
import {
  createProgressMapFromDays,
  createRecoveryMapFromDays,
} from "../utils/member-activity-recovery/overViewMaps";
import { isMobile } from "../utils/screen-utils/isMobile";
import { getMeasurementLengthUnit } from "../utils/units/unitOfLengthUtils";
import { MeasurementInfo } from "../../types/MeasurementClientInterface";
import moment from "moment";
import { addSportActivitiesToTrainingDayPreview } from "../utils/sport-activities";
import {
  createTrainingWeekItemData,
  getAverageRatingFromTrainingDays,
  getCompletedTrainings,
} from "../utils/member-activity-training/trainingsUtils";
import { dateRangeToSystemDateRange } from "../utils/date/dateUtils";
import { Day } from "src/types/RecoveryTypes";

interface NutritionOverview {
  days: NutritionOverviewMap;
  budgetAverage: {
    trainingDays: NutritionAverageFactsMap;
    nonTrainingDays: NutritionAverageFactsMap;
  };
  budgetFactsMap: {
    trainingDays: NutritionFactsMap;
    nonTrainingDays: NutritionFactsMap;
  };
}

export default class OverviewService {
  constructor(
    private readonly behaviorClient: BehaviourInterface,
    private readonly nutritionClient: NutritionInterface,
    private readonly trainingClient: TrainingInterface,
    private readonly recoveryClient: RecoveryInterface,
    private readonly measurementClient: ResultsInterface,
    private readonly sportActivityClient: SportActivitiesInterface
  ) {}

  fetchBehaviourOverview = async (
    userId: string,
    dateRange: DateRange
  ): Promise<BehaviourOverviewMap> => {
    const { days } = await this.behaviorClient.getDateRange(userId, dateRange);
    return createBehaviourWeekOverviewMap(days);
  };

  fetchNutritionOverview = async (
    userId: string,
    dateRange: DateRange
  ): Promise<NutritionOverview> => {
    const { calorieBudget } =
      await this.nutritionClient.fetchCalorieBudgetByDateRange(
        userId,
        dateRangeToSystemDateRange(dateRange)
      );

    const { days } =
      await this.nutritionClient.fetchNutritionProgressByDateRange(
        userId,
        dateRange
      );
    return {
      days: nutritionDaysToMap(days),
      budgetFactsMap: {
        trainingDays: createFactsMapFromCalorieBudget(
          calorieBudget.forTrainingDay
        ),
        nonTrainingDays: createFactsMapFromCalorieBudget(
          calorieBudget.forNonTrainingDay
        ),
      },
      budgetAverage: this.nutritionClient.getAverageFactsForDayRange(
        days,
        calorieBudget
      ),
    };
  };

  fetchTrainingsOverview = async (
    userId: string,
    dateRange: DateRange
  ): Promise<{
    overview: TrainingOverview;
    trainingWeekItems: TrainingWeekItem[];
    averageRating: string;
    completedTrainings: string;
  }> => {
    const { days } = await this.trainingClient.getRange(userId, dateRange);
    const { sportActivitiesPerDate } =
      await this.sportActivityClient.fetchSportActivitiesPerDate(
        userId,
        dateRangeToSystemDateRange(dateRange)
      );
    return {
      // TODO: TS - Incompatible types with shared-componensts (resolved with type casting)
      overview: createTrainingOverview(days) as TrainingOverview,
      trainingWeekItems: createTrainingWeekItemData(
        addSportActivitiesToTrainingDayPreview(days, sportActivitiesPerDate),
        () => null
      ),
      averageRating: getAverageRatingFromTrainingDays(days),
      // TODO: TS - Incompatible types with shared-componensts
      completedTrainings: getCompletedTrainings(days as any),
    };
  };

  fetchRecoveryOverview = async (
    userId: string,
    dateRange: DateRange
  ): Promise<{
    recovery: Map<string, string[]>;
    progression: ProgressOverviewMap;
    disabledChecklistItems: string[];
  }> => {
    const { days, disabledChecklistItems } =
      await this.recoveryClient.getDateRange(userId, dateRange);

    return {
      // TODO: TS - Incompatible types with shared-componensts (resolved with type casting)
      recovery: createRecoveryMapFromDays(days as Day[], !isMobile()),
      progression: createProgressMapFromDays(
        days as Day[],
        disabledChecklistItems,
        !isMobile()
      ),
      disabledChecklistItems: disabledChecklistItems,
    };
  };

  fetchMeasurementInfo = async (userId: string): Promise<MeasurementInfo> => {
    const measurementInfo = await this.measurementClient.getMeasurementInfo(
      userId,
      getWeightUnit(),
      getMeasurementLengthUnit()
    );

    const shouldFetchPhotos = this.shouldFetchPhotosAsBase64(
      measurementInfo.lastMeasurement
    );

    if (shouldFetchPhotos) {
      const base64Photos = await this.measurementClient.getMeasurementPhotos(
        userId,
        measurementInfo.lastMeasurement.createdAt.slice(0, 10), // TODO: not here,
        false
      );
      return {
        ...measurementInfo,
        lastMeasurement: {
          ...measurementInfo.lastMeasurement,
          ...base64Photos,
        },
        isMeasurementsCollapsible: shouldFetchPhotos,
      };
    }
    return { ...measurementInfo, isMeasurementsCollapsible: shouldFetchPhotos };
  };

  private shouldFetchPhotosAsBase64 = (measurement: {
    createdAt: string;
  }): boolean => {
    if (!measurement) return false;
    const next7Days = moment(measurement.createdAt).add(7, "d");
    return moment().isBetween(moment(measurement.createdAt), next7Days);
  };
}
