import { DateTimeProviderImpl, MutableLocale } from "@almservices-cl/clock";
import moment from "moment";
import { TIMEZONE } from "../localStoreKeys";

const fixDstTimezone = (timezone: string): string => {
  const provider = new DateTimeProviderImpl(new MutableLocale("nl_NL"));
  if (provider.isDst(moment())) {
    const hoursStr = timezone.substring(1, 3);
    const hours = parseInt(hoursStr, 10);
    const fixedHours = `${hours + 1}`;

    return timezone.replace(
      hoursStr,
      fixedHours.length > 1 ? fixedHours : `0${fixedHours}`
    );
  }
  return timezone;
};

const getTimezone = (): string => {
  const defaultTimezone = "+01:00";
  try {
    const timezone = localStorage.getItem(TIMEZONE) || defaultTimezone;

    return timezone;
  } catch {
    return defaultTimezone;
  }
};

const setTimezone = (timezoneValue: string): void => {
  localStorage.setItem(TIMEZONE, timezoneValue);
};

export { getTimezone, setTimezone, fixDstTimezone };
