import i18n from "../../i18n";

export const logoutModalTranslations = {
  confirmTranslations: {
    confirm: i18n.t("navigation.logoutModal.logOut"),
    title: i18n.t("navigation.logoutModal.logOut"),
    cancel: i18n.t("navigation.logoutModal.cancelLabel"),
  },
  areYouSure: i18n.t("navigation.logoutModal.areYouSure"),
  proceeding: i18n.t("navigation.logoutModal.proceeding"),
};
