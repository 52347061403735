import Message, {
  MessageAttachment,
  MessageLevel,
  MessagePartInterface,
} from "../../../types/Message";
import { ChatMessage } from "../../../types/ChatMessage";
import { extractDateFromDateTime } from "../date/dateUtils";

export const formatMessagePartDate = (
  parts: MessagePartInterface[]
): MessagePartInterface[] => {
  return parts.map((part) => {
    if (part.name === "date") {
      return {
        ...part,
        isoDate: extractDateFromDateTime(part.isoDate),
      };
    }
    return part;
  });
};

export default (
  messages: Message[],
  memberId: string,
  coachName: string,
  memberName: string
): ChatMessage[] =>
  messages.map((message) => {
    return {
      userId: message.authorId,
      username: message.authorId === memberId ? memberName : coachName,
      dateTime: message.message.isoDateTime,
      messageParts: message.level
        ? formatMessagePartDate(message.message.messageParts)
        : message.message.messageParts,
      messageId: message.message.id as string,
      offline: message.message.id === null,
      level: message.level as MessageLevel,
      authorDisplayName: message.authorDisplayName,
      attachment: message.message.attachment as MessageAttachment,
    };
  });
