import styled from "styled-components";
import { EnvironmentSelection } from "@almservices-cl/storybook-admin";

const Wrapper = styled.div`
  display: flex;
`;

const JournalWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  background-color: #ffffff;

  padding: 1rem 1.25rem;
  border: 1px solid #eaeaea;
  margin-bottom: 0.5rem;
  border-radius: 2px;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;

  &:hover {
    border-color: black;
    background-color: #efefef;
  }

  .thumbnail-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 75px;
    width: 90px;
    flex-basis: 90px;
  }

  img {
    max-height: 75px;
    max-width: 75px;
    border-radius: 7px;
  }
`;

const EntryWrapper = styled.div`
  background-color: #ffffff;

  padding: 1rem 1.25rem;
  border: 1px solid #eaeaea;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 2px;

  img {
    max-height: 300px;
    max-width: 300px;
    margin-bottom: 0.5rem;
  }
`;

const EntryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const BreadcrumbsWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  padding-left: 0.5rem;
  margin-right: 1.5rem;
`;

const Breadcrumbs = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: -0.01em;
  line-height: 1.5;
  line-height: normal;
  text-decoration: none;
  color: #1e2120;
  text-decoration: underline;
  margin-left: 0.5rem;
`;

const Aside = styled.div`
  flex: 1;
`;

const Content = styled.div`
  flex: 2;
`;

const Container = styled.div`
  display: flex;

  @media (min-width: 1025px) and (max-width: 1400px) {
    padding: 0 10px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const DashboardLoginContainer = styled.div`
  height: 100vh;
  width: 100vw;
`;

const LoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding-bottom: 2rem;
  width: 100%;
`;

export const SwitchWrapper = styled(Aside)`
  display: flex;
  justify-content: flex-start;
  flex: 2;
  margin-top: 1.5rem;
`;

export const OpenBottomBarLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 50px);
  background: transparent;
`;

const ContentWrapper = styled(Content)<{ customMobileMargin?: string }>`
  width: 100%;
  height: 100%;
  margin-top: 35px;
  padding-right: 70px;
  padding-left: 40px;
  overflow: auto;

  @media (max-width: 1024px) {
    margin: ${(props: { customMobileMargin?: string }): string =>
      props.customMobileMargin ? props.customMobileMargin : "25px 0 0 0"};
    overflow: inherit;
    padding: 0 20px;
  }

  @media (min-width: 1025px) and (max-width: 1300px) {
    padding: 0 10px 0 10px;
  }
`;

const CalorieWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-bottom: 24px;
  margin-top: 25px;
`;

const BarsWrapper = styled(Wrapper)`
  flex-direction: column;
`;

const BottomBarWrapper = styled.div`
  display: flex;
  height: 30px;
  padding-left: 17px;
`;

const SubRoutesWrapper = styled.div`
  height: calc(100% - 40px);
  overflow: auto;
`;

const StyledEnvironmentList = styled(EnvironmentSelection)`
  margin: 60px auto;
  width: 600px;
`;

export {
  Breadcrumbs,
  BreadcrumbsWrapper,
  LoadingWrapper,
  DashboardLoginContainer,
  Wrapper,
  JournalWrapper,
  EntryWrapper,
  EntryHeader,
  Aside,
  Content,
  Container,
  ContentWrapper,
  CalorieWrapper,
  BarsWrapper,
  BottomBarWrapper,
  SubRoutesWrapper,
  StyledEnvironmentList,
};
