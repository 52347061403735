import {
  TrainingWeekDay,
  TrainingWeekDayExtended,
} from "../../../types/TrainingClientInterface";
import { DateVOPersisted } from "../../DateVO";
import { SportActivitiesPerDay } from "@almservices-cl/coach-app-shared-components";

const countSportActivitiesInDateRange = (
  activities: SportActivitiesPerDay[]
): number => {
  return activities.reduce((acc, activity) => {
    return acc + activity.activitiesInDay;
  }, 0);
};

const addSportActivitiesToTrainingDayPreview = (
  trainingDays: TrainingWeekDay[],
  sportActivities: SportActivitiesPerDay[]
): TrainingWeekDayExtended[] => {
  return trainingDays.map((day) => ({
    ...day,
    sportActivities: (sportActivities.find(
      activityByDate(DateVOPersisted(day.date).persistYMD())
    )?.activitiesInDay || 0) as number,
  }));
};

const activityByDate = (date: string) => (activity: { date: string }) =>
  activity.date === date;

export {
  countSportActivitiesInDateRange,
  addSportActivitiesToTrainingDayPreview,
};
