import moment from "moment";
import {
  getDateFormat,
  getTimeFormat,
  isDefaultFormat,
} from "../units/timeFormatUtils";
import { DateRange } from "../../../types/DateRange";
import { DateVOPersisted } from "../../DateVO";
import { fixDstTimezone, getTimezone } from "../units/timezoneUtils";

const toUserDateFormat = (date: string): string => {
  if (isDefaultFormat()) {
    return reverseDateFormat(date);
  }
  return date;
};

const reverseDateFormat = (date: string): string =>
  date.split("-").reverse().join("-");

const setProperDateForDateObject = (dateRaw: string): string => {
  if (isDefaultFormat()) {
    const [date, time] = dateRaw.split(" ");
    return reverseDateFormat(date) + " " + time;
  }
  return dateRaw;
};

const getDayOfWeek = (date: string): number => {
  const formatted = moment(date, getTimeFormat()).format("YYYY-MM-DD");
  // moment().weekDay() has a bug
  return new Date(formatted).getDay();
};

const formatDateRange = (range: DateRange): DateRange | null => {
  if (!range) return null;
  const start = DateVOPersisted(range.from);
  const end = DateVOPersisted(range.to);

  if (isDefaultFormat()) {
    return {
      from: start.persistDMY(),
      to: end.persistDMY(),
    };
  }

  return {
    from: start.persistYMD(),
    to: end.persistYMD(),
  };
};

const formatChatMessageLabelDate = (date: string): string => {
  const formattedDate = getTimeFormat().replace(/-/g, "/").split(" ")[0];
  return moment(date)
    .utcOffset(fixDstTimezone(getTimezone()))
    .format(`${formattedDate}, HH:mm`);
};

const extractDateFromDateTime = (date: string, separator = "/"): string => {
  const dateFormat = getTimeFormat().split(" ")[0];
  return moment(date).format(dateFormat.replace(/-/g, separator));
};

const getDefaultFormattedDate = (date: string): string =>
  moment(date).utcOffset("+0200").format(getDateFormat());

const formatItemDate = <T extends { date: string }>(item: T): T => {
  return {
    ...item,
    date: toUserDateFormat(item.date),
  };
};

const dateRangeToSystemDateRange = (dateRange: DateRange): DateRange => {
  return {
    from: DateVOPersisted(dateRange.from).persistYMD(),
    to: DateVOPersisted(dateRange.to).persistYMD(),
  };
};

export {
  reverseDateFormat,
  setProperDateForDateObject,
  getDayOfWeek,
  formatDateRange,
  formatChatMessageLabelDate,
  extractDateFromDateTime,
  toUserDateFormat,
  getDefaultFormattedDate,
  formatItemDate,
  dateRangeToSystemDateRange,
};
