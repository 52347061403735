import i18n from "../../../i18n";
import { UNIT_OF_LENGTH } from "../localStoreKeys";
import { Unit } from "@almservices-cl/coach-app-shared-components";
import { UnitsOfLength } from "../../../types/MeasurementClientInterface";

const getMeasurementLengthUnit = (): Unit => {
  const defaultUnitOfLength = Unit.METRIC;
  try {
    return (
      (localStorage.getItem(UNIT_OF_LENGTH) as Unit) || defaultUnitOfLength
    );
  } catch {
    return defaultUnitOfLength;
  }
};

const getMeasurementUnitTranslation = (): UnitsOfLength =>
  getMeasurementLengthUnit() === Unit.METRIC
    ? { main: i18n.t("units.cm"), sub: i18n.t("units.cm") }
    : { main: i18n.t("units.ft"), sub: i18n.t("units.in") };

const setUnitOfLength = (unit: Unit): void => {
  localStorage.setItem(UNIT_OF_LENGTH, unit);
};

export {
  getMeasurementLengthUnit,
  getMeasurementUnitTranslation,
  setUnitOfLength,
};
