import { DateRange } from "../../types/DateRange";
import { Day, DayList } from "../../types/RecoveryTypes";
import { DateValue, DateVOPersisted } from "../DateVO";
import { RecoveryInterface } from "@almservices-cl/coach-app-shared-components";
import Member from "../../types/Member";

export default class RecoveryService {
  constructor(private recoveryClient: RecoveryInterface) {}

  getDateRange = (user: Member, dateRange: DateRange): Promise<DayList> => {
    // TODO: TS - Incompatible types with shared-componensts (resolved with type casting)
    return this.recoveryClient.getDateRange(user.id, {
      from: DateVOPersisted(dateRange.from).persist(),
      to: DateVOPersisted(dateRange.to).persist(),
    }) as Promise<DayList>;
  };

  getRecoveryDayData = (user: Member, date: DateValue): Promise<Day> => {
    // TODO: TS - Incompatible types with shared-componensts (resolved with type casting)
    return this.recoveryClient.getDate(user.id, date.persist()) as Promise<Day>;
  };
}
