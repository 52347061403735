import commonTranslations from "../../../locales/translations/commonTranslations";

export interface CustomExceptionObject {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exceptionType: any; // TODO: change, ts coverage is not skipping this
  customErrorMessage: string;
}

export default (
  objects: CustomExceptionObject[],
  exceptionThrown: unknown
): string => {
  const thrownObj: CustomExceptionObject | undefined = objects.find(
    (obj: CustomExceptionObject): boolean =>
      exceptionThrown instanceof obj.exceptionType
  );
  if (!thrownObj) return commonTranslations.errors.general;
  return thrownObj.customErrorMessage;
};
