export default (endpoint: string, isProduction: boolean): boolean => {
  const protocolWithPortArr: string[] = endpoint.split(/:/);
  const protocol: string = protocolWithPortArr[0];
  let port: number;
  try {
    port = getPort(protocolWithPortArr);
  } catch (e) {
    return false;
  }
  return (
    isProtocolSupported(protocol) &&
    isProtocolConsistent(protocol, port) &&
    isProduction
  );
};
const isProtocolConsistent = (protocol: string, port: number): boolean => {
  return (
    !port ||
    (protocol === "http" && port === 80) ||
    (protocol === "https" && port === 443)
  );
};
const isProtocolSupported = (protocol: string): boolean => {
  return protocol === "http" || protocol === "https";
};

const getPort = (protocolWithPortArr: string[]): number => {
  const port = parseInt(protocolWithPortArr[protocolWithPortArr.length - 1]);
  if (isNaN(port)) {
    if (protocolWithPortArr[0] === "http") return 80;
    if (protocolWithPortArr[0] === "https") return 443;
    throw new Error("Protocol not supported");
  }
  return port;
};
