import { CapacityInput } from "../../types/CapacityItemInput";
import { CapacityItem } from "../../types/CapacityItem";
import { CapacityValues } from "../../types/CapacityValues";
import deepCopy from "../utils/deepCopy";
import { subscriptionTypes } from "../../types/SubscriptionTypes";
import InvalidInputException from "../../types/Exceptions/InvalidInputException";
import NoDataAvailableException from "../../types/Exceptions/NoDataAvailableException";
import { CoachInterface } from "@almservices-cl/coach-app-shared-components";
import { CapacityTypes } from "../../types/CapacityTypes";

export interface CapacityServiceInterface {
  getCapacities: () => Promise<CapacityBundle>;
  changeCapacity: (capacityMaximum: CapacityInput) => Promise<void>;
  calculateAvailableCapacity: (capacityValues: CapacityValues) => number;
  recalculateCapacity: (
    capacityTableIndex: number,
    value: string,
    capacityState: RecalculatedCapacityBundle
  ) => RecalculatedCapacityBundle;
  resolveInputValue: (
    name: string,
    capacity: CapacityItem,
    subType: string
  ) => string;
}

interface CapacityBundle {
  capacities: CapacityItem[];
  premiumMaximum: number;
  originalMaximum: number;
  plusMaximum: number;
  proMaximum: number;
  vipMaximum: number;
}

interface RecalculatedCapacityBundle {
  capacities: CapacityItem[];
  capacityMaximum: CapacityInput;
}

export default class CapacityService implements CapacityServiceInterface {
  constructor(private coachClient: CoachInterface) {}

  getCapacities = async (): Promise<CapacityBundle> => {
    const capacities = await this.coachClient.getCapacity();
    const firstWeek: CapacityItem | undefined = capacities[0];
    if (firstWeek === undefined) throw new NoDataAvailableException();

    return {
      capacities,
      premiumMaximum: firstWeek.premium.maximum,
      originalMaximum: firstWeek.original.maximum,
      plusMaximum: firstWeek.plus.maximum,
      proMaximum: firstWeek.pro.maximum,
      vipMaximum: firstWeek.vip.maximum,
    };
  };

  resolveInputValue = (
    name: string,
    capacity: CapacityItem,
    subType: string
  ): string => {
    const newValue: number =
      name !== CapacityTypes.AVAILABLE
        ? // TODO: TS - Type casting due to bad impelemntation (dynamic property access...)
          (capacity[subType as keyof CapacityItem] as CapacityValues)[
            name as keyof CapacityValues
          ]
        : this.calculateAvailableCapacity({
            maximum: (
              capacity[subType as keyof CapacityItem] as CapacityValues
            )[CapacityTypes.MAXIMUM],
            reserved: (
              capacity[subType as keyof CapacityItem] as CapacityValues
            )[CapacityTypes.RESERVED],
            taken: (capacity[subType as keyof CapacityItem] as CapacityValues)[
              CapacityTypes.TAKEN
            ],
          });
    return isNaN(newValue) ? "0" : newValue.toString();
  };
  calculateAvailableCapacity = (capacityValues: CapacityValues): number =>
    this.coachClient.countAvailableCapacity(capacityValues);

  private updateCapacityMaximum = (
    capacity: CapacityItem,
    capacityTableIndex: number,
    value: number
  ): CapacityItem => {
    capacity[
      subscriptionTypes[capacityTableIndex] as "original" | "plus" | "premium"
    ].maximum = value;
    return {
      ...capacity,
    };
  };

  recalculateCapacity = (
    capacityTableIndex: number,
    value: string,
    capacityState: RecalculatedCapacityBundle
  ): RecalculatedCapacityBundle => {
    const valueNumber = parseInt(value);
    const changedCapacities = capacityState.capacities.map(
      (capacity: CapacityItem): CapacityItem =>
        this.updateCapacityMaximum(capacity, capacityTableIndex, valueNumber)
    );
    const copiedCapacityMaximum: CapacityInput = deepCopy<CapacityInput>(
      capacityState.capacityMaximum
    );

    copiedCapacityMaximum[
      subscriptionTypes[capacityTableIndex] as "original" | "plus" | "premium"
    ].maximum = valueNumber;

    return {
      capacities: changedCapacities,
      capacityMaximum: copiedCapacityMaximum,
    };
  };

  changeCapacity = async (capacityMaximum: CapacityInput): Promise<void> => {
    this.validateCapacityData(capacityMaximum);
    await this.coachClient.setCapacity(capacityMaximum);
  };

  private validateCapacityData = (capacityInput: CapacityInput): void => {
    if (
      isNaN(capacityInput.premium.maximum) ||
      isNaN(capacityInput.plus.maximum) ||
      isNaN(capacityInput.original.maximum) ||
      isNaN(capacityInput.pro.maximum) ||
      isNaN(capacityInput.vip.maximum)
    )
      throw new InvalidInputException();
  };
}
