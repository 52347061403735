import { TIME_FORMAT } from "../localStoreKeys";
import { dateTimeFormatterConfig } from "@almservices-cl/coach-app-shared-components";
import alertErrorHandler from "../error-handlers/alertErrorHandler";

const defaultTimeFormat = "DD-MM-YYYY HH:mm";

const isDefaultFormat = (format?: string): boolean =>
  (format || getTimeFormat()) === defaultTimeFormat;

const getTimeFormat = (): string => {
  try {
    return localStorage.getItem(TIME_FORMAT) || defaultTimeFormat;
  } catch {
    return defaultTimeFormat;
  }
};

const setTimeFormat = async (timeFormat: string): Promise<void> => {
  const [date, time] = timeFormat.split(" ");
  try {
    localStorage.setItem(TIME_FORMAT, timeFormat);
    await dateTimeFormatterConfig.setFormats(timeFormat, date, time);
  } catch (e) {
    alertErrorHandler(e);
  }
};

const getDateFormat = () => getTimeFormat().split(" ")[0];

export { getTimeFormat, setTimeFormat, isDefaultFormat, getDateFormat };
