import {
  CustomerInterface,
  NutritionInterface,
} from "@almservices-cl/coach-app-shared-components";
import { CalorieBudget } from "../../types/CalorieBudget";
import { CalorieBudgetInput } from "../../types/CalorieBudgetInput";
import {
  convertUnit,
  getUnitFromLocalStore,
} from "../utils/units/unitOfWeightUtils";
import CoachService from "./CoachService";
import Member from "../../types/Member";

interface CalorieBudgetBundle {
  calorieBudgets: [CalorieBudget, CalorieBudget];
  isSecured: boolean;
}

export interface CalorieBudgetServiceInterface {
  fetchCalorieBudget: (customer: Member) => Promise<CalorieBudgetBundle>;
  setCalorieBudgets: (
    customer: Member,
    calorieBudgetInput: [CalorieBudgetInput, CalorieBudgetInput],
    isSecured: boolean
  ) => Promise<void>;
  recalculateCalories: (
    carbohydrates: number,
    proteins: number,
    fats: number
  ) => Promise<number>;
  getMemberFoodMethod(customer: Member): Promise<string>;
}

export default class CalorieBudgetService
  implements CalorieBudgetServiceInterface
{
  constructor(
    private customerClient: CustomerInterface,
    private nutritionClient: NutritionInterface,
    private userDataProvider: CoachService
  ) {}

  fetchCalorieBudget = async (
    customer: Member
  ): Promise<CalorieBudgetBundle> => {
    const calorieBudgetBundle =
      await this.customerClient.getCustomerCalorieBudget(
        customer.id,
        convertUnit(getUnitFromLocalStore())
      );

    return {
      calorieBudgets: [
        this.setOrderOfFacts(calorieBudgetBundle.forTrainingDay),
        this.setOrderOfFacts(calorieBudgetBundle.forNonTrainingDay),
      ],
      isSecured: calorieBudgetBundle.isSecured,
    };
  };

  setOrderOfFacts = ({
    fats,
    proteins,
    calories,
    carbohydrates,
  }: CalorieBudget): CalorieBudget => ({
    calories,
    carbohydrates,
    proteins,
    fats,
  });

  setCalorieBudgets = async (
    customer: Member,
    calorieBudgetInputs: [CalorieBudgetInput, CalorieBudgetInput],
    isSecured: boolean
  ): Promise<void> => {
    return this.customerClient.setCalorieBudgets(
      customer.id,
      calorieBudgetInputs.map((budget) => {
        Reflect.deleteProperty(budget, "isSecured");
        return budget;
      }),
      convertUnit(getUnitFromLocalStore()),
      isSecured
    );
  };

  recalculateCalories = async (
    carbohydrates: number,
    proteins: number,
    fats: number
  ): Promise<number> => {
    return this.nutritionClient.calculateCalories(
      carbohydrates,
      proteins,
      fats,
      convertUnit(getUnitFromLocalStore())
    );
  };

  getMemberFoodMethod = async (customer: Member): Promise<string> => {
    const member = await this.customerClient.getCustomer(
      customer.id,
      this.userDataProvider.getUserTimeZone()
    );
    return member.method;
  };
}
