import Member from "../../types/Member";
import {
  NotificationActionsType,
  CustomerInterface,
  Observable,
  ObservableInterface,
} from "@almservices-cl/coach-app-shared-components";
import { Note } from "../../types/Note";
import { IntakeInfo } from "../../types/MemberIntake";
import SubscriptionInfo, { Subscription } from "../../types/SubscriptionInfo";
import { UserFoodPreference } from "../../types/FoodPrefeerence";
import { MemberNotifications } from "../../types/MemberNotifications";
import { intakeTranslations } from "../../locales/translations/intakeTranslations";
import CoachService from "./CoachService";
import { CoachingDays, Counters } from "../../types/CoachingDays";

export interface MemberListServiceInterface {
  fetchMembers: (id?: string) => ObservableInterface<MemberList>;
  fetchSubscriptionInfo: (customer: Member) => Promise<SubscriptionInfo>;
  getMemberIntakeInfo: (customer: Member) => Promise<IntakeInfo>;
  markUserAsRead: (customer: Member) => Promise<void>;
  fetchCustomerNote: (customer: Member) => Promise<Note>;
  saveCustomerNote: (customer: Member, note: string) => void;
  fetchMemberById: (memberId: string) => Promise<Member>;
  getFoodPreferences: (customer: Member) => Promise<UserFoodPreference>;
  changeCoachingDay: (customer: Member, days: CoachingDays) => Promise<void>;
  listenForNotifications: (customer: Member) => Observable<MemberNotifications>;
  unSubscribeMemberList(id: string): Promise<void>;
  fetchMemberNotificationActions(
    memberId: string
  ): Promise<NotificationActionsType[]>;
  dismissNotificationAction(notificationActionId: string): Promise<void>;
}

export interface MemberList {
  items: Member[];
  counters?: Counters;
}

export default class MemberListService implements MemberListServiceInterface {
  private readonly fetchMemberByIdPromises: Map<string, Promise<Member>>;

  constructor(
    private customerClient: CustomerInterface,
    private userDataProvider: CoachService
  ) {
    this.fetchMemberByIdPromises = new Map();
  }

  // TODO: add hasUpgraded to CustomerListItemResponse
  fetchMembers = (subscriptionId?: string): ObservableInterface<MemberList> => {
    const timeZone = this.userDataProvider.getUserTimeZone();
    // TODO: TS - Incompatible types with shared-components
    return this.customerClient.getCustomers(
      timeZone,
      subscriptionId
    ) as unknown as ObservableInterface<MemberList>;
  };

  unSubscribeMemberList = async (id: string): Promise<void> => {
    this.customerClient.unSubscribeCustomerList(id);
  };

  fetchSubscriptionInfo = async (
    customer: Member
  ): Promise<SubscriptionInfo> => {
    return await this.customerClient
      .getCustomerSubscriptions(customer.id)
      .then((subscriptionInfo): SubscriptionInfo => {
        // TODO: TS - Incompatible types with shared-components (resolved with type casting)
        return {
          ...subscriptionInfo,
          current: {
            ...subscriptionInfo.current,
            periodType: subscriptionInfo.current.subscriptionType,
          },
          previous: subscriptionInfo.previous.map((p) => ({
            ...p,
            periodType: p.subscriptionType,
          })),
          next: subscriptionInfo.next.map(
            (p): Subscription =>
              ({
                ...p,
                periodType: p.subscriptionType,
              } as Subscription)
          ),
        } as SubscriptionInfo;
      });
  };

  getMemberIntakeInfo = async (customer: Member): Promise<IntakeInfo> => {
    const intakeInfo = await this.customerClient.getLatestIntakeInfo(
      customer.id
    );
    return this.translateMemberIntakeQuestions(intakeInfo);
  };

  private translateMemberIntakeQuestions = async (
    intakeInfo: IntakeInfo
  ): Promise<IntakeInfo> => {
    const intakeQuestionsToTranslate = intakeInfo.questions;
    intakeQuestionsToTranslate.forEach(
      (question): string =>
        (question.category =
          intakeTranslations.questions.questionValues[
            question.category as
              | "MEDICAL"
              | "HABITS"
              | "NUTRITION"
              | "EXERCISES"
              | "RECOVERY"
          ])
    );
    intakeInfo.questions = intakeQuestionsToTranslate;
    return intakeInfo;
  };

  markUserAsRead = async (customer: Member): Promise<void> => {
    await this.customerClient.markUserAsRead(customer.id);
  };

  fetchCustomerNote = (customer: Member): Promise<Note> => {
    return this.customerClient.getCustomerNote(customer.id);
  };

  saveCustomerNote = (customer: Member, note: string): void => {
    this.customerClient.setCustomerNote(customer.id, note);
  };

  fetchMemberById = (memberId: string): Promise<Member> => {
    if (this.fetchMemberByIdPromises.has(memberId)) {
      return this.fetchMemberByIdPromises.get(memberId) as Promise<Member>;
    }
    const timeZone = this.userDataProvider.getUserTimeZone();

    // TODO: TS - Incompatible types with shared-components (resolved with type casting)
    const promise = this.customerClient.getCustomer(
      memberId,
      timeZone
    ) as Promise<Member>;

    this.fetchMemberByIdPromises.set(memberId, promise);

    promise.finally(() => {
      this.fetchMemberByIdPromises.delete(memberId);
    });

    return this.fetchMemberByIdPromises.get(memberId) as Promise<Member>;
  };

  getFoodPreferences = (customer: Member): Promise<UserFoodPreference> => {
    return this.customerClient.getFoodPreferences(customer.id);
  };

  changeCoachingDay = (customer: Member, days: CoachingDays): Promise<void> => {
    return this.customerClient.setCoachingDays(customer.id, days);
  };

  listenForNotifications = (
    customer: Member
  ): Observable<MemberNotifications> =>
    this.customerClient.getCustomerNotifications(
      customer.id
    ) as Observable<MemberNotifications>;

  fetchMemberNotificationActions = async (
    memberId: string
  ): Promise<NotificationActionsType[]> => {
    return await this.customerClient.getCustomerActions(memberId);
  };

  dismissNotificationAction = async (
    notificationActionId: string
  ): Promise<void> => {
    return this.customerClient.dismissCustomerAction(notificationActionId);
  };
}
