import i18n from "../../../i18n";
import { NutritionFactsMap } from "@almservices-cl/storybook-admin";
import { days } from "../../../locales/translations/timeTranslations";
import {
  NutritionFacts,
  NutritionOverviewMap,
} from "../../../types/NutritionClientInterface";
import { isMobile } from "../screen-utils/isMobile";
import { CalorieBudget } from "../../../types/CalorieBudget";
import { NutritionProgressRangeItem } from "@almservices-cl/coach-app-shared-components";

const createFactsMapFromFacts = (facts: NutritionFacts): NutritionFactsMap => {
  const map = new Map();

  map.set("calories", Math.round(facts.calories));
  map.set("carbs", Math.round(facts.carbs));
  map.set("protein", Math.round(facts.protein));
  map.set("fats", Math.round(facts.fats));
  map.set("fibers", Math.round(facts.fibers));

  return map;
};

const createFactsMapFromCalorieBudget = (
  budget: CalorieBudget
): NutritionFactsMap => {
  const map = new Map();

  map.set("calories", Math.round(budget.calories));
  map.set("carbs", Math.round(budget.carbohydrates));
  map.set("protein", Math.round(budget.proteins));
  map.set("fats", Math.round(budget.fats));
  map.set("fibers", Math.round(budget.fibers as number));

  return map;
};

const prepareDayLabel = (isTrainingDay: boolean, dayNumber: number): string => {
  const trainingDayLabel =
    isTrainingDay && !isMobile()
      ? `- ${i18n.t("nutrition.dayPreview.trainingDay")}`
      : "";

  return `${days[dayNumber]} ${trainingDayLabel}`;
};

const nutritionDaysToMap = (
  nutritionDays: NutritionProgressRangeItem[]
): NutritionOverviewMap => {
  const map: NutritionOverviewMap = new Map();

  if (!nutritionDays || nutritionDays.length === 0) return map;

  nutritionDays.forEach((day): void => {
    const isTrainingDay = day.dayType === "TRAINING";

    if (day.method === "handpalm") {
      map.set(days[day.day], {
        isTrainingDay,
        facts: i18n.t("nutrition.weekOverview.handpalmMethod"),
        calorieBudgetInfo: day.calorieBudgetInfo,
      });
    } else {
      map.set(prepareDayLabel(isTrainingDay, day.day), {
        isTrainingDay,
        facts: createFactsMapFromFacts(day.nutritionFacts as NutritionFacts),
        calorieBudgetInfo: day.calorieBudgetInfo,
      });
    }
  });

  return map;
};

export { nutritionDaysToMap, createFactsMapFromCalorieBudget };
