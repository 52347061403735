import { Day } from "../../../types/BehaviorClientsInterface";
import { days as daysTranslations } from "../../../locales/translations/timeTranslations";
import { isMobile } from "../screen-utils/isMobile";

interface LessonPreview {
  label: string;
  isDone?: boolean;
  shouldRenderStatus: boolean;
  onClick(): void;
}

export const shouldRenderLessonStatus = (
  dayOrdinalNumber: number,
  label: string
): boolean => {
  if (!(label.trim().split(" ").length > 1)) {
    return false;
  }

  return dayOrdinalNumber !== 5 && dayOrdinalNumber !== 6;
};

const prepareLessonData = (lessonTitle: string): string => {
  const [prefix, number] = lessonTitle.split(" ");
  const fixNumber = number ? number : "";
  return `${prefix} ${fixNumber.replace(":", "")}`;
};

const prepareLessonTitle = (label: string, lessonTitle: string): string =>
  isMobile()
    ? `${
        lessonTitle
          ? `${label} - ${prepareLessonData(lessonTitle)}`
          : `${label}`
      }`
    : `${lessonTitle !== null ? `${label} - ${lessonTitle}` : `${label}`}`;

export default (
  days: Day[],
  onItemClick: (date: string) => void
): LessonPreview[] => {
  return days
    .filter((day) => !!day)
    .map((day): LessonPreview => {
      const label = daysTranslations[day.day];
      const lessonLabel = prepareLessonTitle(label, day.lessonTitle as string);
      return {
        label: lessonLabel,
        isDone:
          day.lessonOrdinalNumber && day.lessonOrdinalNumber !== 0
            ? // TODO: TS - isDone can't be wrapped in Boolean() due to bad implementation (isDone can be null)
              (day.isDone as boolean)
            : (null as any),
        shouldRenderStatus: shouldRenderLessonStatus(day.day, lessonLabel),
        onClick: () => onItemClick(day.date),
      };
    })
    .filter((day) => {
      if (isMobile()) {
        if (!day.label || day.label === "null") return false;
        return !day.label.includes("Feit");
      }
      return true;
    });
};
