import { useState, useMemo, FC } from "react";
import { Link, useHistory } from "react-router-dom";
import { LoginServiceInterface } from "../../business-logic/services/LoginService";
import {
  TopNavigation,
  LogoutModal,
  Resources,
  DropDown,
} from "@almservices-cl/storybook-admin";
import { logoutModalTranslations } from "../../locales/translations/logoutModalTranslations";
import CoachPersonalInfo from "../../types/CoachPersonalInfo";
import { useLogoutContext } from "../contexts/logout.context";
import buildDashboardUrl from "../../business-logic/utils/buildDashboardUrl";
import {
  Application,
  useApplicationContext,
} from "../contexts/application.context";
import { getLinksGroups } from "./TopNavigationLinks";
import { getAvailableContexts, Role } from "../../business-logic/utils/pmp";

interface Props {
  coach: CoachPersonalInfo | null;
  loginService: LoginServiceInterface;
}

const AdminHeader: FC<Props> = ({ coach, loginService }) => {
  const { cleanUpTracker } = useLogoutContext();
  const { application } = useApplicationContext();
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  const logout = async (): Promise<void> => {
    await loginService.signOutUser();
    cleanUpTracker();
    window.location.href = "/";
  };

  const toggleShowModal = (): void => setShowModal((showModal) => !showModal);
  const links = useMemo(
    () => getLinksGroups(application)(toggleShowModal),
    [application]
  );

  const onChangeAppContext = (appContext: Application): void => {
    if (appContext === "PMP") {
      return history.push(buildDashboardUrl("member-activity", appContext));
    }
    history.push(buildDashboardUrl("member-activity", appContext));
  };

  return (
    <>
      <TopNavigation
        isLogged={!!coach}
        logoSVG={<Resources.CLCoachDashboardLogo />}
        logoLink={<Link to="/" />}
        dropdown={
          <DropDown
            coachName={coach?.name || ""}
            appCtx={application}
            linksGroups={links}
            onCtXChange={onChangeAppContext}
            canChangeCtx={
              getAvailableContexts(coach?.roles as Role[]).length > 1
            }
          />
        }
      />
      {showModal && (
        <LogoutModal
          translations={logoutModalTranslations}
          onCloseModal={toggleShowModal}
          onLogout={logout}
        />
      )}
    </>
  );
};

export default AdminHeader;
