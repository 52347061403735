import { Route as ReactRoute } from "react-router";
import buildDashboardUrl from "./business-logic/utils/buildDashboardUrl";
import GenericRoute from "./GenericRoute";
import Container from "./container/Container";
import { useApplicationContext } from "./presentation-layer/contexts/application.context";
import Loading from "./presentation-layer/components/Loading";
import { useHistory } from "react-router-dom";

interface DefaultProps {
  container: Container;
  children: JSX.Element;
}

export const LoginRoute = (props: DefaultProps): JSX.Element => (
  <ReactRoute
    exact={true}
    path="/login"
    render={(): JSX.Element => props.children}
  />
);

export const PasswordResetRoute = (props: DefaultProps): JSX.Element => (
  <ReactRoute
    exact={true}
    path="/password-reset"
    render={(): JSX.Element => props.children}
  />
);

export const CoachingDashboardRoute = (props: DefaultProps): JSX.Element => (
  <ReactRoute
    exact={false}
    path={buildDashboardUrl()}
    render={(): JSX.Element => props.children}
  />
);
export const PersonalMindPlanRoute = (props: DefaultProps): JSX.Element => (
  <ReactRoute
    exact={false}
    path={buildDashboardUrl("", "PMP")}
    render={(): JSX.Element => props.children}
  />
);
export const CoachingDashboardRouteCoachProfile = (
  props: DefaultProps
): JSX.Element => {
  const { application, setApplicationContext } = useApplicationContext();
  const { location } = useHistory();
  return (
    <ReactRoute
      exact={false}
      path={"/:app/coach-profile"}
      render={(): JSX.Element => {
        if (
          application === "PBP" &&
          location.pathname.includes(buildDashboardUrl("", "PMP"))
        ) {
          setApplicationContext("PMP");
          return <Loading />;
        } else if (
          application === "PMP" &&
          location.pathname.includes(buildDashboardUrl("", "PBP"))
        ) {
          setApplicationContext("PBP");
          return <Loading />;
        }

        return props.children;
      }}
    />
  );
};
export const CoachingDashboardRouteMemberActivity = (
  props: DefaultProps
): JSX.Element => {
  const { application, setApplicationContext } = useApplicationContext();
  return (
    <ReactRoute
      exact={false}
      path={[
        buildDashboardUrl("member-activity/:activity/:memberId/:date", "PBP"),
        buildDashboardUrl("member-activity/:activity/:memberId", "PBP"),
        buildDashboardUrl("member-activity/:memberId", "PBP"),
        buildDashboardUrl("member-details/:activity/:memberId", "PBP"),
        buildDashboardUrl("member-utils/:activity/:memberId", "PBP"),
        buildDashboardUrl("member-*", "PBP"),
      ]}
      render={(): JSX.Element => {
        if (application !== "PBP") {
          setApplicationContext("PBP");
          return <Loading />;
        }

        return props.children;
      }}
    />
  );
};

export const PMPDashboardRouteMemberActivity = (
  props: DefaultProps
): JSX.Element => {
  const { application, setApplicationContext } = useApplicationContext();

  return (
    <ReactRoute
      exact={false}
      path={[
        buildDashboardUrl("member-activity/:activity/:memberId/:date", "PMP"),
        buildDashboardUrl("member-activity/:activity/:memberId", "PMP"),
        buildDashboardUrl("member-activity/:memberId", "PMP"),
        buildDashboardUrl("member-details/:activity/:memberId", "PMP"),
        buildDashboardUrl("member-utils/:activity/:memberId", "PMP"),
        buildDashboardUrl("member-*", "PMP"),
      ]}
      render={(): JSX.Element => {
        if (application !== "PMP") {
          setApplicationContext("PMP");
          return <Loading />;
        }

        return props.children;
      }}
    />
  );
};

export const CoachingDashboardCapacity = (props: DefaultProps): JSX.Element => (
  <GenericRoute
    exact
    path={buildDashboardUrl("coach-profile/capacity")}
    userDataProvider={props.container.getCoachService()}
    component={(): JSX.Element => props.children}
  />
);

export const CoachingDashboardRatings = (props: DefaultProps): JSX.Element => (
  <GenericRoute
    exact
    path={buildDashboardUrl("coach-profile/ratings")}
    userDataProvider={props.container.getCoachService()}
    component={(): JSX.Element => props.children}
  />
);
export const CoachingDashboardCoachDetails = (
  props: DefaultProps
): JSX.Element => (
  <GenericRoute
    exact
    path={"/:app/coach-profile/coach-details"}
    userDataProvider={props.container.getCoachService()}
    component={(): JSX.Element => props.children}
  />
);

export const CoachingDashboardMonthlyOverview = (
  props: DefaultProps
): JSX.Element => (
  <GenericRoute
    exact
    path={buildDashboardUrl("coach-profile/monthly-overview")}
    userDataProvider={props.container.getCoachService()}
    component={(): JSX.Element => props.children}
  />
);

export const CoachingDashboardPersonalInfo = (
  props: DefaultProps
): JSX.Element => (
  <GenericRoute
    exact={true}
    path={buildDashboardUrl("member-details/personal-info/:id")}
    userDataProvider={props.container.getCoachService()}
    component={(): JSX.Element => props.children}
  />
);
export const CoachingDashboardLifestyleId = (
  props: DefaultProps
): JSX.Element => (
  <GenericRoute
    exact={true}
    path={buildDashboardUrl("member-details/lifestyle-id/:id")}
    userDataProvider={props.container.getCoachService()}
    component={(): JSX.Element => props.children}
  />
);

export const CoachingDashboardLog = (props: DefaultProps) => (
  <GenericRoute
    exact={true}
    path={buildDashboardUrl("member-details/log/:id")}
    userDataProvider={props.container.getCoachService()}
    component={(): JSX.Element => props.children}
  />
);

export const CoachingDashboardOverview = (props: DefaultProps): JSX.Element => (
  <GenericRoute
    exact={true}
    path={buildDashboardUrl("member-activity/overview/:memberId/:date")}
    userDataProvider={props.container.getCoachService()}
    component={(): JSX.Element => props.children}
  />
);

export const CoachingDashboardLife = (props: DefaultProps): JSX.Element => (
  <GenericRoute
    exact={true}
    path={buildDashboardUrl("member-activity/life/:memberId/:date")}
    userDataProvider={props.container.getCoachService()}
    component={(): JSX.Element => props.children}
  />
);

export const CoachingDashboardLifeDetails = (
  props: DefaultProps
): JSX.Element => (
  <GenericRoute
    exact={true}
    path={buildDashboardUrl(
      "member-activity/life/:memberId/:date/entry/:entryId"
    )}
    userDataProvider={props.container.getCoachService()}
    component={(): JSX.Element => props.children}
  />
);

export const CoachingDashboardMind = (props: DefaultProps): JSX.Element => (
  <GenericRoute
    exact={true}
    path={buildDashboardUrl("member-activity/mind/:memberId/:date")}
    userDataProvider={props.container.getCoachService()}
    component={(): JSX.Element => props.children}
  />
);

export const CoachingDashboardMindDetails = (
  props: DefaultProps
): JSX.Element => (
  <GenericRoute
    exact={true}
    path={buildDashboardUrl(
      "member-activity/mind/:memberId/:date/entry/:entryId"
    )}
    userDataProvider={props.container.getCoachService()}
    component={(): JSX.Element => props.children}
  />
);

export const CoachingDashboardNutrition = (
  props: DefaultProps
): JSX.Element => (
  <GenericRoute
    exact={true}
    path={buildDashboardUrl("member-activity/nutrition/:memberId/:date")}
    userDataProvider={props.container.getCoachService()}
    component={(): JSX.Element => props.children}
  />
);
export const CoachingDashboardTraining = (props: DefaultProps): JSX.Element => (
  <GenericRoute
    exact={true}
    path={buildDashboardUrl("member-activity/training/:memberId/:date")}
    userDataProvider={props.container.getCoachService()}
    component={(): JSX.Element => props.children}
  />
);
export const CoachingDashboardBehaviour = (
  props: DefaultProps
): JSX.Element => (
  <GenericRoute
    exact={true}
    path={buildDashboardUrl("member-activity/behaviour/:memberId/:date")}
    userDataProvider={props.container.getCoachService()}
    component={(): JSX.Element => props.children}
  />
);
export const CoachingDashboardRecovery = (props: DefaultProps): JSX.Element => (
  <GenericRoute
    exact={true}
    path={buildDashboardUrl("member-activity/recovery/:memberId/:date")}
    userDataProvider={props.container.getCoachService()}
    component={(): JSX.Element => props.children}
  />
);
export const CoachingDashboardMeasurement = (
  props: DefaultProps
): JSX.Element => (
  <GenericRoute
    exact={true}
    path={buildDashboardUrl("member-activity/measurement/:memberId/")}
    userDataProvider={props.container.getCoachService()}
    component={(): JSX.Element => props.children}
  />
);
export const CoachingDashboardMemberActivity = (
  props: DefaultProps
): JSX.Element => (
  <GenericRoute
    exact={false}
    path={buildDashboardUrl("member-activity")}
    userDataProvider={props.container.getCoachService()}
    component={(): JSX.Element => props.children}
  />
);
export const CoachingDashboardMemberDetails = (
  props: DefaultProps
): JSX.Element => (
  <GenericRoute
    exact={false}
    path={buildDashboardUrl("member-details")}
    userDataProvider={props.container.getCoachService()}
    component={(): JSX.Element => props.children}
  />
);

export const CoachingDashboardUtils = (props: DefaultProps): JSX.Element => {
  const { application } = useApplicationContext();
  return (
    <GenericRoute
      exact={false}
      path={buildDashboardUrl("member-utils/", application)}
      userDataProvider={props.container.getCoachService()}
      component={(): JSX.Element => props.children}
    />
  );
};

export const CoachingDashboardUtilsChat = (
  props: DefaultProps
): JSX.Element => {
  const { application } = useApplicationContext();

  return (
    <GenericRoute
      exact={false}
      path={buildDashboardUrl("member-utils/chat/:memberId", application)}
      userDataProvider={props.container.getCoachService()}
      component={(): JSX.Element => props.children}
    />
  );
};

export const CoachingDashboardUtilsNote = (
  props: DefaultProps
): JSX.Element => {
  return (
    <GenericRoute
      exact={false}
      path={buildDashboardUrl("member-utils/note/:memberId")}
      userDataProvider={props.container.getCoachService()}
      component={(): JSX.Element => props.children}
    />
  );
};
