import { TrainingWeekDay } from "../../../types/TrainingClientInterface";
import { getDayNameByResolution } from "./overviewUtils";

export type TrainingOverview = Map<
  string,
  { value?: boolean | string; shouldShow: boolean; rating?: number }
>;

const createTrainingOverview = (
  days: TrainingWeekDay[]
): TrainingOverview | null => {
  if (!days || days.length === 0) return null;

  const map: TrainingOverview = new Map();

  days.forEach((day) => {
    map.set(getDayNameByResolution(day.day), {
      shouldShow: day.dayType === "TRAINING",
      value: Boolean(day.isDone),
      rating: day.rate as number,
    });
  });

  return map;
};

export { createTrainingOverview };
