import i18n from "../../i18n";

export default {
  errors: {
    authError: i18n.t("common.errors.authError"),
    general: i18n.t("common.errors.general"),
    invalidInput: i18n.t("common.errors.invalidInput"),
  },
  update: {
    saved: i18n.t("common.update.saved"),
  },
  OfflineInfoCard: {
    actions: i18n.t("offlineInfoCard.actions"),
    noInternet: i18n.t("offlineInfoCard.noInternet"),
    tryLater: i18n.t("offlineInfoCard.tryLater"),
  },
};
