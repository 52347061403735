import { useContext, useState, createContext, useMemo, FC } from "react";
import CoachPersonalInfo from "../../types/CoachPersonalInfo";

export type CoachContextType = {
  coach: CoachPersonalInfo;
  setCoach(coach: CoachPersonalInfo): void;
};

const CoachContext = createContext<CoachContextType>(
  null as unknown as CoachContextType
);

const useCoachContext = (): CoachContextType => {
  const context = useContext(CoachContext);
  if (!context) {
    throw new Error("Coach context outside of provider");
  }
  return context;
};

const CoachContextProvider: FC = (props) => {
  const [coach, setCoach] = useState<CoachPersonalInfo>(
    null as unknown as CoachPersonalInfo
  );

  const value = useMemo(() => ({ coach, setCoach }), [coach]);
  return <CoachContext.Provider value={value} {...props} />;
};

export { CoachContext, useCoachContext, CoachContextProvider };
