import { Day } from "../../../types/BehaviorClientsInterface";
import { shouldRenderLessonStatus } from "../member-activity-recovery/mapDaysToViewProps";
import { getDayNameByResolution } from "./overviewUtils";

type DayNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export type BehaviourOverviewMap = Map<
  string,
  { value?: boolean; shouldShow: boolean }
>;

export const countDayNumber = (
  lastDayNumber: number,
  currentDayIdx: number,
  emptyArrayLength: number
): DayNumber => {
  const dayNumber = lastDayNumber - emptyArrayLength + currentDayIdx;
  if (dayNumber > 6) {
    return (dayNumber - 7) as DayNumber;
  }

  if (dayNumber < 0) {
    return (dayNumber + 7) as DayNumber;
  }

  return dayNumber as DayNumber;
};

export const createBehaviourWeekOverviewMap = (
  days: Day[]
): BehaviourOverviewMap => {
  const map: BehaviourOverviewMap = new Map();
  if (!days || days.length === 0) return map;

  if (days.length <= 6) {
    const arrLength = 7 - days.length;
    const emptyArray = new Array(arrLength).fill(null).map(
      (_: null, idx): Day => ({
        day: countDayNumber(days[0].day, idx, arrLength),
        lessonTitle: "",
        isDone: false,
        date: "",
        // TODO: TS - type casting due to bad implementation & type inconsistencies
        lessonOrdinalNumber: null as unknown as number,
      })
    );
    days.splice(0, 0, ...emptyArray);
  }

  days.forEach((day) => {
    map.set(getDayNameByResolution(day.day), {
      // TODO: TS - isDone can't be wrapped in Boolean() due to bad implementation (isDone can be null)
      value: day.isDone as boolean,
      shouldShow: shouldRenderLessonStatus(day.day, "" + day.lessonTitle),
    });
  });
  return map;
};
