import { NavLink as Link } from "react-router-dom";
import { Icon } from "@almservices-cl/storybook-admin";
import { faSignOutAlt } from "@fortawesome/pro-light-svg-icons/faSignOutAlt";
import i18n from "../../i18n";
import buildDashboardUrl from "../../business-logic/utils/buildDashboardUrl";
import { Application } from "../../types/Application";

type LinksGroup = {
  title: JSX.Element | string;
  links: JSX.Element[];
};

const coachingGroup = {
  title: i18n.t("navigation.dropDownGroups.coaching"),
  links: [
    <Link
      key={"dashboard"}
      to={buildDashboardUrl("member-activity")}
      activeClassName="navlink-active"
    >
      {i18n.t("navigation.left.dashboard")}
    </Link>,
    <Link
      key={"monthlyOverview"}
      to={buildDashboardUrl("coach-profile/monthly-overview")}
      activeClassName="navlink-active"
    >
      {i18n.t("dashboard.bottomNavigation.links.monthlyOverview")}
    </Link>,
    <Link
      key={"ratings"}
      to={buildDashboardUrl("coach-profile/ratings")}
      activeClassName="navlink-active"
    >
      {i18n.t("navigation.left.ratings")}
    </Link>,
    <Link
      key={"capacity"}
      to={buildDashboardUrl("coach-profile/capacity")}
      activeClassName="navlink-active"
    >
      {i18n.t("navigation.left.capacity")}
    </Link>,
  ],
};

const accountGroup = {
  title: i18n.t("navigation.dropDownGroups.account"),
  links: [
    <Link
      key={"coachDetails"}
      to={buildDashboardUrl("coach-profile/coach-details")}
      activeClassName="navlink-active"
    >
      <a>{i18n.t("navigation.left.coachDetails")}</a>
    </Link>,
  ],
};

export const externalLinks = [
  {
    href: "https://academy.changinglife.cl",
    title: i18n.t("navigation.left.academy"),
  },
  {
    href: "https://changinglife.typeform.com/to/BwXClg",
    title: i18n.t("navigation.left.bugReporting"),
  },
];

const externalLinksGroup = {
  title: i18n.t("navigation.dropDownGroups.links"),
  links: externalLinks.map(({ href, title }) => (
    <a key={title} href={href} target={"_blank"} rel="noreferrer">
      {title}
    </a>
  )),
};

const logoutGroup = (displayLogoutModal: () => void) => ({
  title: (
    <div onClick={displayLogoutModal}>
      {i18n.t("navigation.right.logOut")}
      <Icon
        className={"pdl-4"}
        shape={"rectangleSmall"}
        icon={faSignOutAlt}
        color={"grey"}
        size={"1x"}
      />
    </div>
  ),
  links: [] as JSX.Element[],
});

export const getLinksGroups =
  (ctx: Application) =>
  (displayLogoutModal: () => void): LinksGroup[] => {
    return ctx === "PBP"
      ? [
          coachingGroup,
          accountGroup,
          externalLinksGroup,
          logoutGroup(displayLogoutModal),
        ]
      : [accountGroup, externalLinksGroup, logoutGroup(displayLogoutModal)];
  };
