import {
  LifeJournalDailyEntries as ApiLifeJournalDailyEntries,
  MindJournalDailyEntries as ApiMindJournalDailyEntries,
} from "@almservices-cl/coach-app-shared-components/dist/ApiLayers/JournalApiInterface";
import { JournalInterface } from "@almservices-cl/coach-app-shared-components/dist/BusinessLogicLayer/JournalInterface";
import { getDayNameByResolution } from "../utils/member-activity-weekoverview/overviewUtils";

type JournalEntryShared = {
  id: string;
  title: string;
  note: string;

  media: {
    sizes: Record<
      "thumbnail" | "large",
      {
        url: string;
      }
    >;
  };
};

export type LifeJournalDailyEntry = JournalEntryShared;

export type MindJournalDailyEntry = JournalEntryShared & {
  location: string | null;
  activity: string | null;
  companion: string | null;
  mood: {
    name: string;
    category: string;
  };
};

export type JournalWeeklyActivities = Record<
  "lifeEntriesActivity" | "mindEntriesActivity",
  { hasActivity: boolean; day: string }[]
>;

export default class JournalService {
  constructor(private journalClient: JournalInterface) {}

  getLifeJournalDailyEntries = async (
    customerId: string,
    date: string
  ): Promise<LifeJournalDailyEntry[]> => {
    return this.mapLifeJournalEntries(
      await this.journalClient.getLifeJournalEntry(customerId, date)
    );
  };

  getMindJournalDailyEntries = async (
    customerId: string,
    date: string
  ): Promise<MindJournalDailyEntry[]> => {
    return this.mapMindJournalEntries(
      await this.journalClient.getMindJournalEntry(customerId, date)
    );
  };

  getJournalWeeklyActivity = async (
    customerId: string,
    startDate: string,
    endDate: string
  ): Promise<JournalWeeklyActivities> => {
    const [lifeEntries, mindEntries] = [
      (await this.journalClient.getJournalWeeklyEntries(
        "life",
        customerId,
        startDate,
        endDate
      )) as ApiLifeJournalDailyEntries,
      (await this.journalClient.getJournalWeeklyEntries(
        "mind",
        customerId,
        startDate,
        endDate
      )) as ApiMindJournalDailyEntries,
    ];
    return {
      lifeEntriesActivity: lifeEntries.progressCollection.map(
        ({ lifeJournalEntry }, index) => ({
          hasActivity: !!lifeJournalEntry.length,
          // swap first and last day of the week
          day: getDayNameByResolution(index === 0 ? 6 : index - 1),
        })
      ),
      mindEntriesActivity: mindEntries.progressCollection.map(
        ({ mindMoodEntry }, index) => ({
          hasActivity: !!mindMoodEntry.length,
          // swap first and last day of the week
          day: getDayNameByResolution(index === 0 ? 6 : index - 1),
        })
      ),
    };
  };

  mapLifeJournalEntries = (
    lifeJournalEntries: ApiLifeJournalDailyEntries
  ): LifeJournalDailyEntry[] => {
    return lifeJournalEntries.progressCollection
      .map(({ lifeJournalEntry }) =>
        lifeJournalEntry.map((entry) => {
          return {
            ...entry,
            media: entry.media ?? {
              sizes: {
                large: {
                  url: "/img/daily-journal-entry-fallback.png",
                },
                thumbnail: {
                  url: "/img/daily-journal-entry-fallback.png",
                },
              },
            },
          };
        })
      )
      .flat();
  };

  mapMindJournalEntries = (
    mindJournalEntries: ApiMindJournalDailyEntries
  ): MindJournalDailyEntry[] => {
    return mindJournalEntries.progressCollection
      .map(({ mindMoodEntry }) =>
        mindMoodEntry.map((entry) => {
          return {
            ...entry,
            media: entry.media ?? {
              sizes: {
                large: {
                  url: "/img/mind-journal-entry-fallback.png",
                },
                thumbnail: {
                  url: "/img/mind-journal-entry-fallback.png",
                },
              },
            },
          };
        })
      )
      .flat();
  };
}
