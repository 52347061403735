import i18n from "../../i18n";

export const days = [
  i18n.t("sidebar.userDetailCard.monday"),
  i18n.t("sidebar.userDetailCard.tuesday"),
  i18n.t("sidebar.userDetailCard.wednesday"),
  i18n.t("sidebar.userDetailCard.thursday"),
  i18n.t("sidebar.userDetailCard.friday"),
  i18n.t("sidebar.userDetailCard.saturday"),
  i18n.t("sidebar.userDetailCard.sunday"),
];

export const daysShortNames = i18n.t("time.shortcutsOfDays", {
  returnObjects: true,
});

export const months = [
  i18n.t("time.months.january"),
  i18n.t("time.months.february"),
  i18n.t("time.months.march"),
  i18n.t("time.months.april"),
  i18n.t("time.months.may"),
  i18n.t("time.months.june"),
  i18n.t("time.months.july"),
  i18n.t("time.months.august"),
  i18n.t("time.months.september"),
  i18n.t("time.months.october"),
  i18n.t("time.months.november"),
  i18n.t("time.months.december"),
];
