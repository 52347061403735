import {
  useState,
  useEffect,
  createContext,
  useMemo,
  useContext,
  FC,
} from "react";
import IdleTimer from "@almservices-cl/idle-tracker";
import LoginService from "../../business-logic/services/LoginService";
import { useHistory } from "react-router-dom";

export type LogoutContextType = {
  isPreLogoutModalVisible: boolean;
  hidePreLogoutModal(): void | Promise<void>;
  startTrackingUserActivity(): void;
  cleanUpTracker(): void;
};

const LogoutContext = createContext<LogoutContextType>(
  null as unknown as LogoutContextType
);

const useLogoutContext = (): LogoutContextType => {
  const context = useContext(LogoutContext);

  if (!context) {
    throw Error("Logout context outside of provider");
  }
  return context;
};

interface Props {
  loginService: LoginService;
}
const TimeConfig = {
  logoutTime: 30 * 60,
  preLogoutTime: 1 * 60,
};

const LogoutContextProvider: FC<Props> = (props) => {
  const history = useHistory();
  const [isPreLogoutModalVisible, togglePreLogout] = useState(false);
  const [isTrackerOn, toggleTracker] = useState(false);
  let tracker: IdleTimer;

  const startTrackingUserActivity = (): void => toggleTracker(true);

  const showPreLogoutModal = (): void => togglePreLogout(true);

  const hidePreLogoutModal = (): void => togglePreLogout(false);

  const cleanUpTracker = (): void => tracker && tracker.cleanUp();

  const logout = async (): Promise<void> => {
    await props.loginService.signOutUser();
    hidePreLogoutModal();
    toggleTracker(false);
    history.push("/");
  };

  useEffect(() => {
    if (isTrackerOn) {
      tracker = new IdleTimer({
        onTimeout: logout,
        onPreTimeout: showPreLogoutModal,
        timeout: TimeConfig.logoutTime,
        preTimeout: TimeConfig.preLogoutTime,
      });
    }

    return cleanUpTracker;
  }, [isTrackerOn]);

  const value = useMemo(
    () => ({
      isPreLogoutModalVisible,
      hidePreLogoutModal,
      startTrackingUserActivity,
      cleanUpTracker,
    }),
    [isPreLogoutModalVisible]
  );

  return <LogoutContext.Provider value={value} {...props} />;
};

export { LogoutContext, useLogoutContext, LogoutContextProvider };
