import { Component } from "react";
import Loading from "./presentation-layer/components/Loading";
import { Redirect } from "react-router-dom";
import CoachService from "./business-logic/services/CoachService";

interface Props {
  component: () => JSX.Element;
  userDataProvider: CoachService;
}

interface State {
  isAuth: null | boolean;
}

class AuthMiddleware extends Component<Props, State> {
  state: State = {
    isAuth: null,
  };

  componentDidMount(): void {
    this.checkAuth();
  }

  checkAuth = (): void => {
    this.props.userDataProvider
      .isUserAuthenticated()
      .then((isAuth: boolean): void => {
        this.setState({ isAuth });
      });
  };

  render(): JSX.Element {
    if (this.state.isAuth === null) return <Loading />;
    if (this.state.isAuth === false) return <Redirect to={"/login"} />;

    return this.props.component();
  }
}

export default AuthMiddleware;
