import { ENTER_TO_SEND } from "../localStoreKeys";

const getEnterToSend = (): boolean => {
  try {
    return localStorage.getItem(ENTER_TO_SEND) === "1" || false;
  } catch {
    return false;
  }
};

const setEnterToSend = (enterToSend: boolean): void =>
  localStorage.setItem(ENTER_TO_SEND, enterToSend ? "1" : "");

export { getEnterToSend, setEnterToSend };
