import { useContext, useState, createContext, useMemo, FC } from "react";

type ApplicationContextModalType = {
  isModalVisible: boolean;
  setIsModalVisible(isVisible: boolean): void;
};

const ApplicationContextModal = createContext<ApplicationContextModalType>(
  null as unknown as ApplicationContextModalType
);

const useApplicationContextModal = (): ApplicationContextModalType => {
  const context = useContext(ApplicationContextModal);
  if (!context) {
    throw new Error("Application context modal outside of provider");
  }
  return context;
};

const ApplicationContextModalProvider: FC = (props) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const value = useMemo(
    () => ({ isModalVisible, setIsModalVisible }),
    [isModalVisible]
  );
  return <ApplicationContextModal.Provider value={value} {...props} />;
};

export {
  useApplicationContextModal,
  ApplicationContextModal,
  ApplicationContextModalProvider,
};
export type { ApplicationContextModalType };
