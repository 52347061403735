import { Component } from "react";
import EnvironmentController from "./presentation-layer/controllers/EnvironmentController";
import EnvironmentService from "./business-logic/services/EnvironmentService";
import MainRoutes from "./Routes";
import Container from "./container/Container";
import Loading from "./presentation-layer/components/Loading";
import {
  boot,
  type,
  Bootable,
  EnvironmentType,
} from "@almservices-cl/coach-app-shared-components";
import cache from "./cache-layer/Cache";
import getGlobalVar from "./business-logic/utils/env-utils/getGlobalVar";
import globalVars from "./globalVars";
import castTo from "./business-logic/utils/castTo";
import { isOffline, updateOnlineStatus } from "./networkStatus";
import {
  getLanguage,
  setHttpHeaderLanguage,
} from "./business-logic/utils/units/languageUtils";
import getRefreshTokenFromExternalUrlSearchParams from "./business-logic/utils/dashboard-login/getRefreshTokenFromExternalUrlSearchParams";
import { captureException } from "@sentry/react";
import {
  getUnitFromLocalStore,
  setHttpHeaderUnit,
} from "./business-logic/utils/units/unitOfWeightUtils";

interface State {
  environment: string | null;
  container: Container | null;
}

// eslint-disable-next-line
export default class extends Component<{}, State> {
  private bootable!: Bootable;

  state: State = {
    environment: "",
    container: null,
  };

  componentDidMount = (): void => {
    this.fetchEnv();
  };

  fetchEnv = async (): Promise<void> => {
    this.checkEnvironment();
  };

  checkEnvironment = async (): Promise<void> => {
    if (process.env.NODE_ENV === "production") {
      if (window.location.host.includes("devplan")) {
        return this.onEnvironmentSetHandler(
          "https://cle-780-backend.personaldevplan.com"
        );
      }
      return this.onEnvironmentSetHandler("https://api.personalbodyplan.com");
    }
    cache
      .getValue("env")
      .onSuccess(this.onEnvironmentSetHandler as (value: unknown) => void)
      .onError(() => {
        this.setState({ environment: null });
      });
  };

  onEnvironmentSetHandler = async (environment: string): Promise<void> => {
    const api: string = getGlobalVar(globalVars.ENV);
    this.bootable = await boot(
      cache,
      castTo<string, type>(api),
      getLanguage(),
      environment,
      process.env.NODE_ENV === "production"
    );

    this.setState({ environment });

    window.addEventListener(
      "offline",
      async (): Promise<void> => {
        try {
          await this.bootable.NetworkSubscriptionType.goOffline();
        } finally {
          updateOnlineStatus("offline");
        }
      },
      false
    );
    window.addEventListener(
      "online",
      async (): Promise<void> => {
        try {
          await this.bootable.NetworkSubscriptionType.goOnline();
        } finally {
          updateOnlineStatus("online");
        }
      },
      false
    );

    await this.bootable.NetworkSubscriptionType.init(isOffline());

    const container = new Container(
      this.bootable.AuthType,
      this.bootable.CustomerType,
      this.bootable.ChatType,
      this.bootable.CoachType,
      this.bootable.NutritionType,
      this.bootable.TrainingType,
      this.bootable.BehaviourType,
      this.bootable.RecoveryType,
      this.bootable.ResultsType,
      this.bootable.TraineeListType,
      this.bootable.HistoryType,
      this.bootable.SportActivitiesType,
      this.bootable.JournalType
    );

    setHttpHeaderLanguage(getLanguage());
    setHttpHeaderUnit(getUnitFromLocalStore());

    const refreshToken = getRefreshTokenFromExternalUrlSearchParams(
      window.location.search
    );
    if (refreshToken) {
      try {
        await container.getLoginService().authByRefreshToken(refreshToken);
      } catch (e) {
        captureException(e);
        throw e;
      }
    }
    (document as Document & { container: Container }).container = container;
    this.setState({ container });
  };

  render(): JSX.Element {
    if (!this.state.environment && process.env.NODE_ENV !== "production")
      return (
        <EnvironmentController
          environmentService={
            new EnvironmentService(
              EnvironmentType(
                castTo<string, type>(getGlobalVar(globalVars.ENV)),
                "http://34.250.216.56:8080"
              )
            )
          }
          onEnvSet={this.onEnvironmentSetHandler}
        />
      );
    if (this.state.container) {
      return <MainRoutes container={this.state.container} />;
    }

    return <Loading />;
  }
}
