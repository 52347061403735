import { isDefaultFormat } from "./utils/units/timeFormatUtils";
import moment from "moment";

export type DateValue = {
  year(): number;
  month(): number;
  day(): number;
  isSameAs(otherDate: DateValue): boolean;
  persist(): string;
  persistReverted(): string;
  persistDMY(): string;
  persistYMD(): string;
};

const DateVOPersisted = (date: string | null): DateValue => {
  let fixedDate = date;
  if (!fixedDate) {
    const d = new Date();
    fixedDate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
  }
  if (fixedDate.match(/[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}(.+)?/)) {
    const [y, m, d] = fixedDate.split("-");
    return DateVO(parseInt(y), parseInt(m), parseInt(d));
  } else {
    const [d, m, y] = fixedDate.split("-");
    return DateVO(parseInt(y), parseInt(m), parseInt(d));
  }
};

const DateVOMoment = (date: moment.Moment): DateValue => {
  return DateVO(date.year(), date.month() + 1, date.date());
};

const DateVO = (year: number, month: number, day: number): DateValue => {
  return {
    day(): number {
      return day;
    },
    isSameAs(otherDate: DateValue): boolean {
      return (
        day === otherDate.day() &&
        month === otherDate.month() &&
        year === otherDate.year()
      );
    },
    month(): number {
      return month;
    },
    year(): number {
      return year;
    },
    persist(): string {
      let returnString =
        year + "-" + ("0" + month).slice(-2) + "-" + ("0" + day).slice(-2);
      if (isDefaultFormat()) {
        returnString =
          ("0" + day).slice(-2) + "-" + ("0" + month).slice(-2) + "-" + year;
      }
      return returnString;
    },
    persistReverted(): string {
      let returnString =
        ("0" + day).slice(-2) + "-" + ("0" + month).slice(-2) + "-" + year;

      if (isDefaultFormat()) {
        returnString =
          year + "-" + ("0" + month).slice(-2) + "-" + ("0" + day).slice(-2);
      }
      return returnString;
    },
    persistDMY(): string {
      return ("0" + day).slice(-2) + "-" + ("0" + month).slice(-2) + "-" + year;
    },
    persistYMD(): string {
      return year + "-" + ("0" + month).slice(-2) + "-" + ("0" + day).slice(-2);
    },
  };
};

export { DateVO, DateVOPersisted, DateVOMoment };
