import i18n from "../../i18n";

export const intakeTranslations = {
  bodyCharacteristic: {
    belly: i18n.t("intake.bodyCharacteristic.belly"),
    buttocks: i18n.t("intake.bodyCharacteristic.buttocks"),
    calves: i18n.t("intake.bodyCharacteristic.calves"),
    chest: i18n.t("intake.bodyCharacteristic.breast"),
    hips: i18n.t("intake.bodyCharacteristic.hips"),
    loveHandles: i18n.t("intake.bodyCharacteristic.loveHandles"),
    neck: i18n.t("intake.bodyCharacteristic.neck"),
    shoulders: i18n.t("intake.bodyCharacteristic.shoulders"),
    upperArms: i18n.t("intake.bodyCharacteristic.upperArms"),
    waist: i18n.t("intake.bodyCharacteristic.waist"),
  },
  bodyCharacteristicTitle: i18n.t("intake.bodyCharacteristicTitle"),
  disc: i18n.t("intake.disc"),
  hormone: i18n.t("intake.hormone"),
  nutritionExperiencePoint: i18n.t("intake.nutritionExperiencePoint"),
  trainingExperiencePoint: i18n.t("intake.trainingExperiencePoint"),
  questions: {
    generalQuestions: i18n.t("intake.questions.generalQuestions"),
    planPurposeQuestions: i18n.t("intake.questions.planPurposeQuestions"),
    category: i18n.t("intake.questions.category"),
    answer: i18n.t("intake.questions.answer"),
    question: i18n.t("intake.questions.question"),
    comment: i18n.t("intake.questions.comment"),
    otherQuestions: i18n.t("intake.questions.otherQuestions"),
    questionValues: {
      MEDICAL: i18n.t("intake.questions.questionValues.medical"),
      HABITS: i18n.t("intake.questions.questionValues.habits"),
      NUTRITION: i18n.t("intake.questions.questionValues.nutrition"),
      EXERCISES: i18n.t("intake.questions.questionValues.exercises"),
      RECOVERY: i18n.t("intake.questions.questionValues.recovery"),
    },
  },
} as const;

export const intakeTableTranslations = {
  intake: i18n.t("intake.tableHeaders.intake"),
  weight: i18n.t("intake.tableHeaders.weight"),
  personal: i18n.t("intake.tableHeaders.personal"),
};
