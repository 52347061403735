import { UNIT_OF_WEIGHT } from "../localStoreKeys";
import {
  GenericWeightUnit,
  NutrientUnit,
  WeightUnit,
} from "../../../types/GenericWeightUnit";
import { setUnit, Unit } from "@almservices-cl/coach-app-shared-components";

const getUnitFromLocalStore = (): GenericWeightUnit => {
  const defaultUnit = "kg/g";
  try {
    return (
      (localStorage.getItem(UNIT_OF_WEIGHT) as GenericWeightUnit) || defaultUnit
    );
  } catch {
    return defaultUnit;
  }
};

const getNutrientUnit = (): NutrientUnit =>
  getUnitFromLocalStore().split("/")[1] as NutrientUnit;

const getWeightUnit = (): WeightUnit =>
  getUnitFromLocalStore().split("/")[0] as WeightUnit;

const convertUnit = (genericUnit: GenericWeightUnit): Unit => {
  return genericUnit === "kg/g" ? Unit.METRIC : Unit.IMPERIAL;
};

const setUnitOfWeight = (unit: string): void => {
  localStorage.setItem(UNIT_OF_WEIGHT, unit);
};

const setHttpHeaderUnit = (unit: GenericWeightUnit) => {
  if (unit === "lbs/oz") {
    setUnit(Unit.IMPERIAL as Unit);
  } else {
    setUnit(Unit.METRIC as Unit);
  }
};

export {
  convertUnit,
  getNutrientUnit,
  getWeightUnit,
  getUnitFromLocalStore,
  setUnitOfWeight,
  setHttpHeaderUnit,
};
