import i18n from "../../i18n";

export default {
  recovery: i18n.t("recovery.recovery"),
  progress: i18n.t("recovery.progress"),
  labelsColumn: {
    heartBeat: i18n.t("recovery.labelColumn.heartbeat"),
    sleepHours: i18n.t("recovery.labelColumn.sleepingHours"),
    sleepQuality: i18n.t("recovery.labelColumn.sleepQuality"),
    muscleStrain: i18n.t("recovery.labelColumn.muscleStrain"),
    stressLevel: i18n.t("recovery.labelColumn.stressLevel"),
  },
  progressLabelColumn: {
    motivation: i18n.t("recovery.labelColumn.motivation"),
    slept: i18n.t("recovery.progressLabelColumn.slept"),
    lesson: i18n.t("recovery.progressLabelColumn.areYouReadLesson"),
    supplements: i18n.t("recovery.progressLabelColumn.supplementsTaken"),
    water: i18n.t("recovery.progressLabelColumn.drankWater"),
    training: i18n.t("recovery.progressLabelColumn.haveYouTrained"),
    nutrition: i18n.t("recovery.progressLabelColumn.recommendedProducts"),
  },
  progressionWeek: {
    motivation: i18n.t("recovery.labelColumn.motivation"),
    slept: i18n.t("recovery.progressLabelColumn.slept"),
    lesson: i18n.t("recovery.progressOverview.areYouReadLesson"),
    nutrition: i18n.t("recovery.progressOverview.recommendedProducts"),
    water: i18n.t("recovery.progressOverview.drankWater"),
    training: i18n.t("recovery.progressOverview.haveYouTrained"),
    supplements: i18n.t("recovery.progressOverview.supplementsTaken"),
  },
};

export const recoveryDayTranslation = {
  motivation: i18n.t("recovery.labelColumn.motivation"),
  heartBeat: i18n.t("recovery.labelColumn.heartbeat"),
  sleepHours: i18n.t("recovery.labelColumn.sleepingHours"),
  sleepQuality: i18n.t("recovery.labelColumn.sleepQuality"),
  muscleStrain: i18n.t("recovery.labelColumn.muscleStrain"),
  stressLevel: i18n.t("recovery.labelColumn.stressLevel"),
};

export const progressDayTranslation = {
  motivation: i18n.t("recovery.labelColumn.motivation"),
  slept: i18n.t("recovery.progressLabelColumn.slept"),
  ateAsRecommended: i18n.t("recovery.progressLabelColumn.recommendedProducts"),
  didLesson: i18n.t("recovery.progressLabelColumn.areYouReadLesson"),
  drankWater: i18n.t("recovery.progressLabelColumn.drankWater"),
  supplemented: i18n.t("recovery.progressLabelColumn.supplementsTaken"),
  trained: i18n.t("recovery.progressLabelColumn.haveYouTrained"),
};
