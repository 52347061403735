import moment from "moment";
import { HistoryItem } from "@almservices-cl/coach-app-shared-components";
import { getDateFormat } from "../units/timeFormatUtils";
import i18n from "../../../i18n";
import { fixDstTimezone, getTimezone } from "../units/timezoneUtils";

const getPrefix = (date: string): string => {
  let prefix = "";
  if (date === moment().format("YYYY-MM-DD")) {
    prefix = i18n.t("time.today");
  }

  if (date === moment().add(-1, "d").format("YYYY-MM-DD")) {
    prefix = i18n.t("time.yesterday");
  }
  return prefix;
};

const changeSeparator = (date: string, separator = "/") =>
  date.split("-").join(separator);

const adaptEventDateTime = (dateTime: string): string => {
  if (!dateTime) return "";

  const [date] = dateTime.split("T");
  return `${getPrefix(date)} ${changeSeparator(
    moment(date).format(getDateFormat())
  )}, ${moment(dateTime)
    .utcOffset(fixDstTimezone(getTimezone()))
    .format("HH:mm")}`.trim();
};

export const adaptEvent = (event: HistoryItem): HistoryItem => {
  return {
    ...event,
    createdAt: adaptEventDateTime(event.createdAt),
  };
};
