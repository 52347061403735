import {
  MeasurementBundle,
  MeasurementPhotos,
} from "../../types/MeasurementClientInterface";
import {
  Unit,
  ResultsInterface,
} from "@almservices-cl/coach-app-shared-components";
import Member from "../../types/Member";

export default class MeasurementService {
  constructor(private measurementClient: ResultsInterface) {}

  getMeasurements = (
    member: Member,
    unit: "kg" | "lbs",
    lengthUnit?: Unit
  ): Promise<MeasurementBundle> => {
    return this.measurementClient.getAvailableMeasurements(
      member.id,
      unit,
      lengthUnit as Unit
    );
  };

  getMeasurementPhoto = (
    member: Member,
    id: string,
    rawPhotos = false
  ): Promise<MeasurementPhotos> => {
    return this.measurementClient.getMeasurementPhotos(
      member.id,
      id,
      rawPhotos
    );
  };

  getPhotosToBase64 = async (
    photos: MeasurementPhotos
  ): Promise<MeasurementPhotos> => {
    return await this.measurementClient.fetchImagesAsBase64(photos);
  };

  fetchImageAsBase64 = async (url: string): Promise<string> => {
    return await this.measurementClient.fetchImageAsBase64(url);
  };
}
