import { Route, RouteComponentProps } from "react-router-dom";
import AuthMiddleware from "./AuthMiddleware";
import { RouteProps as OryginalRouteProps } from "react-router";
import CoachService from "./business-logic/services/CoachService";

interface RouteProps extends RouteComponentProps {
  date?: string;
  memberId?: string;
}

interface Props extends OryginalRouteProps {
  userDataProvider: CoachService;
  component: (routeProps: RouteProps) => JSX.Element;
}

export default ({
  userDataProvider,
  component,
  ...props
}: Props): JSX.Element => {
  return (
    <Route
      {...props}
      render={(routeProps: RouteProps): JSX.Element => (
        <AuthMiddleware
          userDataProvider={userDataProvider}
          component={(): JSX.Element => component(routeProps)}
        />
      )}
    />
  );
};
