import App from "./App";
import { useHistory } from "react-router-dom";
import { UserSettingsContextProvider } from "./presentation-layer/contexts/userSettings.context";

declare global {
  interface Window {
    gtag: UniversalAnalytics.ga;
  }
}

export default (): JSX.Element => {
  const history = useHistory();

  if (window.gtag && typeof window.gtag === "function")
    history.listen((location) => {
      window.gtag("set", "page", location.pathname + location.search);
      window.gtag("send", "pageview");
    });

  return (
    <UserSettingsContextProvider>
      <App />
    </UserSettingsContextProvider>
  );
};
