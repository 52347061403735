import {
  CustomerInterface,
  WeekDayType,
} from "@almservices-cl/coach-app-shared-components";
import Member from "../../types/Member";

class DayTypeService {
  constructor(private client: CustomerInterface) {}

  getDayType = async (
    user: Member,
    date: string
  ): Promise<WeekDayType | null> => {
    return this.client.getDayType(user.id, date);
  };
}

export default DayTypeService;
