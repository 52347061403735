import { FC, lazy, Suspense, ReactNode } from "react";
import { Redirect, Route as ReactRoute, useHistory } from "react-router-dom";
import LoginController from "./presentation-layer/controllers/LoginController";
import Container from "./container/Container";
import {
  CoachingDashboardRouteCoachProfile,
  CoachingDashboardRouteMemberActivity,
  LoginRoute,
  PasswordResetRoute,
  PMPDashboardRouteMemberActivity,
} from "./RoutesCollection";
import { CoachContextProvider } from "./presentation-layer/contexts/coach.context";
import { LogoutContextProvider } from "./presentation-layer/contexts/logout.context";
import Loading from "./presentation-layer/components/Loading";
import {
  Application,
  ApplicationContextProvider,
  useApplicationContext,
} from "./presentation-layer/contexts/application.context";
import {
  ApplicationContextModal,
  ApplicationContextModalProvider,
} from "./presentation-layer/contexts/aplicationContextModal.context";
import { ContextSwitcherModal } from "@almservices-cl/storybook-admin";
import buildDashboardUrl from "./business-logic/utils/buildDashboardUrl";

const ResetPasswordController = lazy(
  () => import("./presentation-layer/controllers/ResetPasswordController")
);
const MemberActivityRoutes = lazy(
  () => import("./presentation-layer/routes/MemberActivityRoutesController")
);
const PMPMemberActivityRoutes = lazy(
  () => import("./presentation-layer/routes/PMPMemberActivityRoutesController")
);
const DashboardController = lazy(
  () => import("./presentation-layer/controllers/DashboardController")
);

const LoggedInRoutesController: FC<{ container: Container }> = ({
  container,
}) => {
  const history = useHistory();
  const { application } = useApplicationContext();

  return (
    <ApplicationContextModal.Consumer>
      {({ isModalVisible, setIsModalVisible }): ReactNode => {
        const onToggleVisibility = () => setIsModalVisible(!isModalVisible);

        const onChangeAppContext = (appContext: Application) => {
          if (appContext === "PMP") {
            return history.push(
              buildDashboardUrl("member-activity", appContext)
            );
          }
          history.push(buildDashboardUrl("member-activity", appContext));
        };
        return (
          <>
            {isModalVisible && (
              <ContextSwitcherModal
                onToggleVisibility={onToggleVisibility}
                onChange={onChangeAppContext}
                selectedContext={application}
              />
            )}
            <CoachingDashboardRouteCoachProfile container={container}>
              <DashboardController
                userDataProvider={container.getCoachService()}
                container={container}
              />
            </CoachingDashboardRouteCoachProfile>
            <CoachingDashboardRouteMemberActivity container={container}>
              <MemberActivityRoutes
                key={"member-activity-routes"}
                container={container}
              />
            </CoachingDashboardRouteMemberActivity>
            <PMPDashboardRouteMemberActivity container={container}>
              <PMPMemberActivityRoutes
                key={"pmp-member-activity-routes"}
                container={container}
              />
            </PMPDashboardRouteMemberActivity>
          </>
        );
      }}
    </ApplicationContextModal.Consumer>
  );
};

interface Props {
  container: Container;
}

export default ({ container }: Props): JSX.Element => {
  const userDataProvider = container.getCoachService();
  return (
    <Suspense fallback={<Loading />}>
      <LogoutContextProvider loginService={container.getLoginService()}>
        <ApplicationContextProvider>
          <LoginRoute container={container}>
            <LoginController
              loginService={container.getLoginService()}
              userDataProvider={userDataProvider}
            />
          </LoginRoute>
          <PasswordResetRoute container={container}>
            <ResetPasswordController
              loginService={container.getLoginService()}
            />
          </PasswordResetRoute>

          <CoachContextProvider>
            <ApplicationContextModalProvider>
              <LoggedInRoutesController container={container} />
            </ApplicationContextModalProvider>
          </CoachContextProvider>
        </ApplicationContextProvider>
      </LogoutContextProvider>
      <ReactRoute
        exact
        path="/"
        render={(): JSX.Element => <Redirect to="/login" />}
      />
    </Suspense>
  );
};
