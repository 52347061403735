import FormException from "./FormException";

export default class AllFieldsRequiredException extends FormException {
  constructor(reason?: string) {
    if (!reason) {
      super("Invalid input");
    } else {
      super(reason);
    }
  }
}
