import {
  Meal,
  MealWithBase64Thumbnail,
  MemberNutritionDay,
} from "../../types/NutritionClientInterface";
import { DateRange } from "../../types/DateRange";
import {
  AuthInterface,
  NutritionInterface,
} from "@almservices-cl/coach-app-shared-components";
import { CalorieBudgetBundle } from "../../types/CalorieBudgetBundle";
import { DateValue } from "../DateVO";
import Member from "../../types/Member";
import { DayList } from "../../types/DayList";
import { dateRangeToSystemDateRange } from "../utils/date/dateUtils";

export default class NutritionService {
  constructor(
    private nutritionClient: NutritionInterface,
    private authClient: AuthInterface
  ) {}

  private validateThumbnailUrl(
    thumbnailUrl: string | undefined
  ): thumbnailUrl is string {
    return typeof thumbnailUrl === "string" && thumbnailUrl.length !== 0;
  }

  /**
   * Due to asset security (bearer token required), images are fetched asynchronously.
   * In order to display the image in the <img /> element (in specific storybook component), the photo is converted to Base64.
   */
  private async mapDailyMealsThumbnailsToBase64(
    meals: Meal[]
  ): Promise<MealWithBase64Thumbnail[]> {
    const token = await (this.authClient as any).tokenService.getValidToken();
    return await Promise.all(
      meals.map(async (meal): Promise<MealWithBase64Thumbnail> => {
        try {
          const thumbnailUrl = meal.image?.thumbnail;

          if (!this.validateThumbnailUrl(thumbnailUrl)) {
            throw new Error("Image thumbnail does not exist or is empty.");
          }

          const thumbnailBuffer = await fetch(thumbnailUrl, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then((res) => res.arrayBuffer());

          return {
            ...meal,
            image: {
              thumbnail: `data:image/png;base64, ${btoa(
                String.fromCharCode(...new Uint8Array(thumbnailBuffer))
              )}`,
            },
          };
        } catch (error) {
          return meal as MealWithBase64Thumbnail;
        }
      })
    );
  }

  getNutritionByDate = async (
    user: Member,
    date: DateValue
  ): Promise<MemberNutritionDay> => {
    // TODO: TS - Incompatible types with shared-componensts (resolved with type casting)
    const dailyNutrition =
      await (this.nutritionClient.getNutritionProgressByDate(
        user.id,
        date.persist()
      ) as Promise<MemberNutritionDay>);

    dailyNutrition.meals = await this.mapDailyMealsThumbnailsToBase64(
      dailyNutrition.meals
    );

    return dailyNutrition;
  };

  getNutritionByDateRange = async (
    user: Member,
    dayRange: DateRange
  ): Promise<DayList> => {
    const { calorieBudget } =
      await this.nutritionClient.fetchCalorieBudgetByDateRange(
        user.id,
        dateRangeToSystemDateRange(dayRange)
      );
    const { days } =
      await this.nutritionClient.fetchNutritionProgressByDateRange(
        user.id,
        dateRangeToSystemDateRange(dayRange)
      );

    return {
      days,
      calorieBudget,
    };
  };

  mapCalorieBudget = (
    calorieBudgetBundle: CalorieBudgetBundle
  ): CalorieBudgetBundle | null => {
    if (calorieBudgetBundle) {
      if (
        calorieBudgetBundle.forTrainingDay.calories === 0 &&
        calorieBudgetBundle.forNonTrainingDay.calories === 0
      )
        return null;
      return calorieBudgetBundle;
    }
    return null; // no calorie budget
  };
}
