import RecoveryTranslations from "../../../locales/translations/recoveryTranslations";
import { Day, RecoveryValues } from "../../../types/RecoveryTypes";
import { ProgressOverviewMap } from "../../../types/RecoveryProgress";
import { WeekDayType } from "@almservices-cl/coach-app-shared-components";

export const countAverageFromDays = (
  recovery: RecoveryValues[],
  label: string
): string | null => {
  if (!recovery.length) return null;

  // TODO: TS - Type casting to unknown due to bad implementation
  const daysWithRating = recovery.filter(
    (day) =>
      day[label as keyof RecoveryValues] !== 0 &&
      !isNaN(parseInt(day[label as keyof RecoveryValues] as unknown as string))
  );
  if (!daysWithRating.length) return "-";

  const total = daysWithRating.reduce(
    (acc, day) => acc + (day[label as keyof RecoveryValues] as number),
    0
  );
  const average = total / daysWithRating.length;

  return Math.round(average).toString();
};

export const countAverageFromMotivation = (
  progress: { value: number; reason: string }[]
): string | null => {
  if (!progress.length) return null;

  const daysWithProgress = progress.filter(({ value }) => value !== 0 && value);

  const total = daysWithProgress.reduce(
    (acc, progress) => acc + progress.value,
    0
  );
  const average = total / daysWithProgress.length;

  if (isNaN(average)) return "-";

  return Math.round(average).toString();
};

const getMotivationProgress = (
  days: Day[]
): { value: number; reason: string }[] =>
  days.map(
    (day) =>
      // TODO: TS - Type casting due to type inconsistencies (nullable vs non-nullable)
      day.progress.motivation as {
        value: number;
        reason: string;
      }
  );

const createRecoveryMapFromDays = (
  days: Day[],
  isAverage = false
): Map<string, string[]> => {
  const recoveryMap = new Map();

  for (const label in RecoveryTranslations.labelsColumn) {
    const daysValues = days.map(
      (day) => day.recovery[label as keyof RecoveryValues]
    );
    const average =
      isAverage &&
      countAverageFromDays(
        days.map((day) => day.recovery),
        label
      );
    recoveryMap.set(
      RecoveryTranslations.labelsColumn[
        label as keyof Omit<RecoveryValues, "sleepHoursRange">
      ],
      average ? [average, ...daysValues] : daysValues
    );
  }
  return recoveryMap;
};

const CHECKLIST_ITEMS = {
  LESSON: "lesson",
  MOTIVATION: "motivation",
  NUTRITION: "nutrition",
  WATER: "water",
  TRAINING: "training",
  SLEEP: "sleep",
  SUPPLEMENTS: "supplements",
};

// eslint-disable-next-line complexity
const createProgressMapFromDays = (
  days: Day[],
  disabledChecklistItems: string[],
  isAverage = false
): ProgressOverviewMap => {
  const progressMap = new Map();
  const translations = isAverage
    ? RecoveryTranslations.progressionWeek
    : RecoveryTranslations.progressLabelColumn;

  // order is important
  const { motivation, slept, nutrition, lesson, training, supplements, water } =
    translations;

  /**
   * Motivation
   */
  const motivationValues = getMotivationProgress(days);
  const hasMotivationValues = motivationValues.some(
    ({ value }) => value !== null
  );
  const average =
    isAverage && countAverageFromMotivation(getMotivationProgress(days));
  const shouldHideMotivation =
    !hasMotivationValues &&
    disabledChecklistItems.includes(CHECKLIST_ITEMS.MOTIVATION);

  if (!shouldHideMotivation) {
    progressMap.set(
      motivation,
      average ? [average, ...motivationValues] : motivationValues
    );
  }

  /**
   * Lessons
   */
  const lessonValues = days.map((day) => {
    if (day.day === 5 || day.day === 6) {
      // " " mean no value (no box)
      return " ";
    }
    return day.progress.lesson;
  });
  const hasLessonValues = lessonValues.some(
    (lesson) => lesson !== " " && lesson.status !== null
  );
  const shouldHideLesons =
    !hasLessonValues && disabledChecklistItems.includes(CHECKLIST_ITEMS.LESSON);

  if (!shouldHideLesons) {
    progressMap.set(lesson, lessonValues);
  }

  /**
   * Nutrition
   */
  const nutritionValues = days.map((day) => day.progress.nutrition);
  const hasNutritionValues = nutritionValues.some(
    (lesson) => lesson.status !== null
  );
  const shouldHideNutrition =
    !hasNutritionValues &&
    disabledChecklistItems.includes(CHECKLIST_ITEMS.NUTRITION);

  if (!shouldHideNutrition) {
    progressMap.set(nutrition, nutritionValues);
  }

  /**
   * Water
   */
  const waterValues = days.map((day) => day.progress.water);
  const hasWaterValues = waterValues.some((lesson) => lesson.status !== null);
  const shouldHideWater =
    !hasWaterValues && disabledChecklistItems.includes(CHECKLIST_ITEMS.WATER);

  if (!shouldHideWater) {
    progressMap.set(water, waterValues);
  }

  /**
   * Training
   */
  const trainingValues = days.map((day) => {
    if (day.dayType !== WeekDayType.TRAINING_DAY) return " ";

    return day.progress.training;
  });
  const hasTrainingValues = trainingValues.some(
    (training) => training !== " " && training.status !== null
  );
  const shouldHideTraining =
    !hasTrainingValues &&
    disabledChecklistItems.includes(CHECKLIST_ITEMS.TRAINING);

  if (!shouldHideTraining) {
    progressMap.set(training, trainingValues);
  }

  /**
   * Sleep
   */
  const sleptValues = days.map((day) => {
    if (!day.recovery.sleepHoursRange) return "-";
    return `${day.recovery.sleepHoursRange.from} - ${day.recovery.sleepHoursRange.to}`;
  });
  const hasSleptValues = sleptValues.some((value) => value !== "-");
  const shouldHideSlept =
    !hasSleptValues && disabledChecklistItems.includes(CHECKLIST_ITEMS.SLEEP);

  if (!shouldHideSlept) {
    progressMap.set(slept, sleptValues);
  }
  /**
   * Supplements
   */
  const supplementsValues = days.map((day) => day.progress.supplements);
  const hasSupplementsValues = supplementsValues.some(
    (supplement) => supplement.status !== null
  );
  const shouldHideSupplements =
    !hasSupplementsValues &&
    disabledChecklistItems.includes(CHECKLIST_ITEMS.SUPPLEMENTS);

  if (!shouldHideSupplements) {
    progressMap.set(supplements, supplementsValues);
  }

  return progressMap;
};

export { createRecoveryMapFromDays, createProgressMapFromDays };
