const isOnline = (mode?: string): boolean => {
  if (mode && mode === "online") {
    return true;
  } else if (mode && mode === "offline") {
    return false;
  }
  return navigator.onLine;
};
const isOffline = (mode?: string): boolean => {
  if (mode && mode === "offline") {
    return true;
  } else if (mode && mode === "online") {
    return false;
  }
  return !navigator.onLine;
};

export interface NetworkObserver {
  (mode: string): void;
}

const observatoriesNetworkStatus: NetworkObserver[] = [];

const observeNetworkStatus = (observer: NetworkObserver): void => {
  observatoriesNetworkStatus.push(observer);
};

const unobserveNetworkStatus = (observer: NetworkObserver): void => {
  observatoriesNetworkStatus.splice(
    observatoriesNetworkStatus.indexOf(observer),
    1
  );
};

const updateOnlineStatus: NetworkObserver = (mode) =>
  observatoriesNetworkStatus.forEach((observatory) => observatory(mode));

export {
  isOnline,
  isOffline,
  observeNetworkStatus,
  updateOnlineStatus,
  unobserveNetworkStatus,
};
