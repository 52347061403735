import { LANGUAGE } from "../localStoreKeys";
import isProductionBuild from "../isProductionBuild";
import { setLanguage as setMutableLanguage } from "@almservices-cl/coach-app-shared-components";

const getLanguage = (): string => {
  const defaultLanguage = isProductionBuild() ? "nl" : "en";

  try {
    return localStorage.getItem(LANGUAGE) || defaultLanguage;
  } catch {
    return defaultLanguage;
  }
};

const setLanguage = (lng: string): void => localStorage.setItem(LANGUAGE, lng);

const setHttpHeaderLanguage = (lng: string) => setMutableLanguage(lng);

export { getLanguage, setLanguage, setHttpHeaderLanguage };
