import { useMemo, FC } from "react";
import { AppContexts, IUserSettings } from "@almservices-cl/storybook-admin";
import { getTimezone } from "../../business-logic/utils/units/timezoneUtils";
import { getTimeFormat } from "../../business-logic/utils/units/timeFormatUtils";

const UserSettingsContextProvider: FC = (props) => {
  const timezone = getTimezone();
  const timeFormat = getTimeFormat();

  const settings = useMemo(
    (): IUserSettings => ({ timeFormat, timezone }),
    [timezone, timeFormat]
  );

  return (
    <AppContexts.UserSettingsContext.Provider value={settings} {...props} />
  );
};

export { UserSettingsContextProvider };
