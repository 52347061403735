import { DayList } from "../../types/BehaviorClientsInterface";
import { BehaviourInterface } from "@almservices-cl/coach-app-shared-components";
import { BehaviourDayInterface } from "../../types/BehaviourDayInterface";
import { DateValue } from "../DateVO";
import Member from "../../types/Member";
import { DateRange } from "../../types/DateRange";

export default class BehaviorService {
  constructor(private behaviorClient: BehaviourInterface) {}

  getMemberDayBehaviour = (
    user: Member,
    date: DateValue
  ): Promise<BehaviourDayInterface> => {
    return this.behaviorClient.getDate(user.id, date.persist());
  };

  getDateRange = async (
    userId: string,
    dateRange: DateRange
  ): Promise<DayList> => {
    // TODO: TS - Incompatible types with shared-componensts (resolved with type casting)
    return this.behaviorClient.getDateRange(
      userId,
      dateRange
    ) as Promise<DayList>;
  };
}
