import { Component } from "react";
import { EnvironmentServiceInterface } from "../../business-logic/services/EnvironmentService";
import Loading from "../components/Loading";
import { Environment } from "../../types/Environment";
import { Templates } from "@almservices-cl/storybook-admin";
import EnvironmentView from "../views/environment/EnvironmentView";
import cache from "../../cache-layer/Cache";
import sortEnvironments from "../../business-logic/utils/env-utils/sortEnvironments";

interface Props {
  environmentService: EnvironmentServiceInterface;
  onEnvSet: (environment: string) => void;
}

interface State {
  environments: Environment[];
  areEnvironmentsFetched: boolean;
  predicateVPN: boolean;
  error: string;
}

export default class EnvironmentController extends Component<Props, State> {
  state: State = {
    environments: [],
    areEnvironmentsFetched: false,
    predicateVPN: false,
    error: "",
  };

  componentDidMount = (): Promise<void> => {
    if (process.env.NODE_ENV !== "production") {
      return this.fetchEnvironments();
    }
    return Promise.resolve(undefined);
  };

  checkEnvironment = async (): Promise<boolean> => {
    try {
      return !!(await cache.getValue("env").asPromise());
    } catch {
      return false;
    }
  };

  fetchEnvironments = async (): Promise<void> => {
    const isEnv = await this.checkEnvironment();
    if (isEnv) return;

    this.props.environmentService
      .getEnvironments()
      .onSuccess((environments: Environment[] | null) => {
        if (environments?.length === 1 && environments[0].isProduction) {
          return this.setState({
            environments,
            areEnvironmentsFetched: true,
            predicateVPN: true,
          });
        }

        this.setState({
          environments: sortEnvironments(environments as Environment[]),
          areEnvironmentsFetched: true,
          predicateVPN: false,
        });
      })
      .onError(this.handleSetError);
  };

  handleSetEnvironment = (option: string): void => {
    this.props.environmentService
      .selectEnvironment(option)
      .then((): void => {
        return this.props.onEnvSet(option);
      })
      .catch(this.handleSetError);
  };

  handleSetError = (err: Error): void => {
    this.setState({ error: `Environment controller: ${err.message}` });
    this.removeNotification();
  };

  removeNotification = (): void => {
    setTimeout(() => this.setState({ error: "" }), 3000);
  };

  render(): JSX.Element {
    if (!this.state.areEnvironmentsFetched) return <Loading />;
    return (
      <Templates.Basic
        body={
          <>
            <EnvironmentView
              environments={this.state.environments}
              onSetEnvironment={this.handleSetEnvironment}
              predicateVPN={this.state.predicateVPN}
            />
          </>
        }
        topUserNavigation={<div />}
        bottomUserNavigation={<div />}
        error={this.state.error}
      />
    );
  }
}
