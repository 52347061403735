import { Environment } from "../../../types/Environment";
import isProductionEnvironment from "./isProductionEnvironment";

export default (environments: Environment[]): Environment[] => {
  return environments.sort((env1, env2): number => {
    if (
      isProductionEnvironment(env1.endpoint, env1.isProduction) ===
      isProductionEnvironment(env2.endpoint, env2.isProduction)
    )
      return 0;
    if (
      isProductionEnvironment(env1.endpoint, env1.isProduction) &&
      !isProductionEnvironment(env2.endpoint, env2.isProduction)
    )
      return -1;
    return 1;
  });
};
