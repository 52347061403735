import { Application } from "../../../types/Application";

type Role = "ROLE_COACH_PMP" | "ROLE_COACH" | "ROLE_USER";

const isPMPCoach = (roles: Role[]): boolean =>
  roles?.includes("ROLE_COACH_PMP");

const isPBPCoach = (roles: Role[]) =>
  roles?.some((role) => role === "ROLE_COACH");

const getDefaultAppContext = (roles: Role[]): Application => {
  if (!roles || isPBPCoach(roles)) return "PBP";

  return "PMP";
};

const getAvailableContexts = (roles: Role[]): Application[] => {
  if (!roles || roles.length === 0) return [];
  const availableContexts: Application[] = [];
  roles.forEach((role) => {
    if (role === "ROLE_COACH") return availableContexts.push("PBP");
    if (role === "ROLE_COACH_PMP") return availableContexts.push("PMP");
    return undefined;
  });

  return availableContexts;
};

export { isPMPCoach, getDefaultAppContext, getAvailableContexts };

export type { Role };
